import Modal from "react-modal"
import Avatar from "../visual/Avatar"
import "./Contact.css"
import { faMapMarkedAlt, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Timeslots from '../timeslot/Timeslots';
import { useState, useEffect } from "react"
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'
import Appointmnet from "../appointment/Appointmnet"
import Loader from "../loader/Loader"
import { useTranslation } from "react-i18next";
import EditAppointment from '../appointment/EditAppointment'
import UpdateContact from "./UpdateContact";
import Warning from "../visual/Warning";
import { compareDates } from "../../utils/DateManuplation.js"
const ContactDetails = ({ appointment, updateAppointment, contact, updateAppointmentStatus, updateContact,contactUpdateAppointment }) => {
    var managerData = null;
    const { t } = useTranslation();
    const DATA_LENGTH = 10
    var offset = 0
    const apiPath = localStorage.getItem("apiPath")
    const appointmentStatusUrl = apiPath + "/v6/appointment/"
    const [updateContactClick, setUpdateContactClick] = useState(false)
    const [timeslotsLodaing, setTimeslotsLodaing] = useState(false)
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const timeslotsUrl = apiPath + "/v5/appointment/available/"
        + "?count=" + DATA_LENGTH + "&zone=" + timeZone
        + "&offset=" + DATA_LENGTH * (offset++) + "&duration=" + 30;

    const unavailableDaysUrl = apiPath + "/v5/appointment/month/unavailable"
        + "?zone=" + timeZone
        + "&duration=" + 30;
    const setAppointmentUrl = apiPath + "/v5/appointment"
    const [isConfirmOpen, setIsConfirmOpen] = useState({ show: false, title: null })
    const [isOpen, setIsOpen] = useState(false)
    const [timeslots, settimeslots] = useState([])
    const [selectedAppointment, setSelectedAppointment] = useState(null)
    const [value, onChange] = useState(new Date());
    const [selectedCalendarDate, setSelectedCalendarDate] = useState();
    const [unavaiableDays, setUnavailableDays] = useState([])

    String.prototype.capitalize = function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    }

    const onDayClicked = async (value) => {
        setTimeslotsLodaing(true)
        var dateLong = new Date(value).getTime()
        setSelectedCalendarDate(dateLong)
        const timeslots = await fetchTimeslots(dateLong)
        setTimeslotsLodaing(false)
        console.log("json:" + JSON.stringify(timeslots))
        console.log("error:" + timeslots.error)
        if (timeslots.error === undefined) {
            settimeslots(timeslots)
        }
        else {
            settimeslots([])
        }

    }

    const onMonthClicked = async ({ activeStartDate, value, view }) => {
        console.log("date:", activeStartDate)
        var dateLong = new Date(activeStartDate).getTime()
        const response = await fetchUnavailableDays(dateLong)
        setUnavailableDays(response);
        console.log("unavailable days:", unavaiableDays)
    }

    const fetchUnavailableDays = async (dateLong) => {
        managerData = JSON.parse(localStorage.getItem("managerData"))
        console.log("managerData from contact details:", managerData)
        const token = localStorage.getItem('react-token');
        const response = await fetch(unavailableDaysUrl + "&managerId=" + managerData.id + "&date=" + dateLong, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                "Authorization": "Bearer " + token
            }
        })
        const responseJson = await response.json()

        return responseJson
    }

    const tileDisabled = ({ activeStartDate, date, view }) => {
        var dateLong = new Date(date)
        //console.log("date:", dateLong.getDate())
        for (var i = 0; i < unavaiableDays.length; i++) {

            if (compareDates(dateLong, new Date(unavaiableDays[i]))) {
                //console.log("date is disabled", dateLong)
                return true
            }
        }
        return false
    }

    useEffect(() => {
        const getTimeslots = async () => {
            const currentDate = new Date().getTime()
            setSelectedCalendarDate(currentDate)
            const unavailableDays = await fetchUnavailableDays(currentDate)
            setUnavailableDays(unavailableDays);
            const timeslots = await fetchTimeslots(currentDate)
            settimeslots(timeslots)
        }
        getTimeslots()
    }, [])

    const fetchTimeslots = async (dateLong) => {
        managerData = JSON.parse(localStorage.getItem("managerData"))
        console.log("managerData from contact details:", managerData)
        const token = localStorage.getItem('react-token');
        const response = await fetch(timeslotsUrl + "&managerId=" + managerData.id + "&date=" + dateLong, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                "Authorization": "Bearer " + token
            }
        })
        const responseJson = await response.json()
        return responseJson
    }

    const onAppointmentClick = (appointment) => {
        setSelectedAppointment(appointment)
        onCloseClicked()
    }

    const updateStatus = async (appointment) => {
        const token = localStorage.getItem('react-token')
        await fetch(appointmentStatusUrl, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(appointment)
        })
        //setContacts([...contacts, contact])
        updateAppointmentStatus(appointment)
    }

    const undoAppointment = (e, appointment) => {
        setSelectedAppointment(appointment)
        e.stopPropagation();
        setIsConfirmOpen({ type: "undo", show: !isConfirmOpen.show, title: t("Undo_appointment_delete_msg") })

    }

    const deleteAppointment = (e, appointment) => {
        setSelectedAppointment(appointment)
        setIsConfirmOpen({ type: "delete", show: !isConfirmOpen.show, title: t("delete_appointment_msg") })
        e.stopPropagation();
    }

    const confrimDeleteAppointment = () => {
        onConfirmCloseClicked()
        console.log(appointment)
        let updatedAppointment = null;
        if (isConfirmOpen.type === "delete")
            updatedAppointment = { ...selectedAppointment, status: 9 }

        if (isConfirmOpen.type === "undo")
            updatedAppointment = { ...selectedAppointment, status: 1 }

        console.log("updated appointment:", updatedAppointment)
        updateStatus(updatedAppointment)

    }

    const confrimDeleteAndSmsAppointment = () => {
        onConfirmCloseClicked()
        console.log(appointment)
        let updatedAppointment = null;
        if (isConfirmOpen.type === "delete")
            updatedAppointment = { ...selectedAppointment, status: 10 }

        if (isConfirmOpen.type === "undo")
            updatedAppointment = { ...selectedAppointment, status: 12 }
        console.log("updated appointment:", updatedAppointment)
        updateStatus(updatedAppointment)

    }

    const dayContent = ({ activeStartDate, date, view }) => {
        if (view === "month") {
            // return "ok"
        }
    }

    const onCloseClicked = async () => {
        //updateing time slots on new appointment set
        const timeslots = await fetchTimeslots(selectedCalendarDate)
        settimeslots(timeslots)
        console.log("refreshing time slots:", timeslots)
        setIsOpen(!isOpen);
    }

    const onOkClicked = async (selectedTimeSlot) => {
        const token = localStorage.getItem('react-token')
        const setAppointment = {
            contact: contact,
            timeSlots: selectedTimeSlot
        }
        const response =  await fetch(setAppointmentUrl, {
            method: "POST",
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(setAppointment)
        })

        //updateing time slots on new appointment set
        const responseJson = await response.json()
        const timeslots = await fetchTimeslots(selectedCalendarDate)
        settimeslots(timeslots)
        console.log("set appointment response:", responseJson)
        updateAppointment(responseJson)
        
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const onConfirmCloseClicked = () => {
        setIsConfirmOpen({ show: !isConfirmOpen.show })

    }

    const onEditContactClicked = () => {
        setUpdateContactClick(!updateContactClick)
    }

    const onEditContactSubmit = (contact) => {
        onEditContactClicked()
        updateContact(contact)
    }

   

    return (
        <>
            <div className="panel-header">
                <div className="contact-item">
                </div>
            </div>

            {contact ? <div className="contactDetails">
                <div className="profile-header card">
                    <div className="column-item border-melow"><Avatar path={contact.avatar} /></div>
                    <div className="profile-header-info">
                        {
                            contact.name === null || contact.lastName === null ? <h3>{t("unknown_contact")}</h3> : <h3>{contact?.name?.capitalize()}&nbsp;{contact?.lastName?.capitalize()}</h3>
                        }

                        <div className="profile-header-info-footer-container">
                            <div className="profile-header-info-footer">
                                <div className="profile-header-info-footer-item color-gray"><FontAwesomeIcon icon={faPhone} />&nbsp;{contact.phone}</div>
                                <div className="profile-header-info-footer-item color-gray margin-left-10px"  ><FontAwesomeIcon icon={faMapMarkedAlt} />&nbsp;{contact.address}</div>
                            </div>
                            <div className="profile-header-info-footer margin-top-5px">
                                <div className="color-gray"><FontAwesomeIcon icon={faEnvelope} />&nbsp;{contact.email}</div>
                            </div>
                        </div>
                        <button className="btn-trasparent" onClick={onEditContactClicked}>{t("edit")}</button>

                    </div>

                </div>
                {updateContactClick === true && <div className="card margin-top-5px"> <UpdateContact contact={contact} updateContact={onEditContactSubmit} onEditContactClicked={onEditContactClicked} /> </div>}
                <div className="contactDetails-top-box card margin-top-5px">
                    <div className="contact-appointments  margin-top-10px">
                        {appointment.map((appointment) => (<Appointmnet key={appointment.id} appointment={appointment} onAppointmentClick={onAppointmentClick} apporoveAppointment={deleteAppointment} denyAppointment={undoAppointment} menu="delete" />))}
                        <EditAppointment updateAppointment={contactUpdateAppointment} ontact={contact} onCloseClicked={onCloseClicked} onOkClicked={onOkClicked} timeslotsLodaing={timeslotsLodaing} onDayClicked={onDayClicked} isOpen={isOpen} timeslots={timeslots} appointment={selectedAppointment} onAppointmentClick={onAppointmentClick} />
                    </div>
                </div>

                <div className="info card margin-top-10px">
                    <div className="left ">
                        <Calendar
                            className="react-calendar"
                            onChange={onDayClicked}
                            next2Label={null}
                            prev2Label={null}
                            onActiveStartDateChange={onMonthClicked}
                            value={value}
                            showNeighboringMonth={false}
                            minDate={new Date()}
                            tileDisabled={tileDisabled}
                            tileContent={dayContent}
                        />
                    </div>
                    <div className="right ">
                        {timeslotsLodaing === true ? <Loader /> : <Timeslots isEdit={false} updateAppointment={updateAppointment} contact={contact} timeslots={timeslots} onOkClicked={onOkClicked} />}
                    </div>
                </div>

                <Modal
                    isOpen={isConfirmOpen.show}
                    className="modal-box-small"
                    overlayClassName="modal-overlay"
                    onRequestClose={onConfirmCloseClicked}
                    contentLabel="this is a test">
                    <div className="modal-container">
                        <div className="modal-title"><h5>{t("confirm")}</h5></div>
                        <div className="modal-content margin-top-5px">
                            <Warning title={t("warning")} content={isConfirmOpen.title} type="warning" />
                        </div>
                        <div className="modal-content-footer-3-btn">
                            <button className="modal-item-btn-red " onClick={onConfirmCloseClicked}>{t("cancel")}</button>
                            <div className="two-btn-container">
                                <button className="modal-item-btn-blue" onClick={confrimDeleteAppointment}>{ isConfirmOpen.type === "delete"? t("confirm_delete") : t("confirm_undo") }</button>
                                <button className="modal-item-btn-nobackground " onClick={confrimDeleteAndSmsAppointment}>{isConfirmOpen.type === "delete"? t("smsSend_delete") : t("smsSend_undo")}</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div> : ""
            }
        </>
    )
}

export default ContactDetails
