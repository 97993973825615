import { useState, useEffect } from "react"
import "./userContactInfo.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserContactInfo from "./UserContactInfo";
import { useLocation } from 'react-router-dom'
import { useTranslation } from "react-i18next";
const AppointmentInfoDetails = ({ appointmentInfo, fToken, onChangeAppointmentClicked, onOkClicked }) => {
    const apiPath = localStorage.getItem("apiPath")
    const { t } = useTranslation();
    const appointmentInfoUrl = apiPath + "/public/contact/info"
    const [date, setDate] = useState()
    const [time, setTime] = useState()

    useEffect(() => {
        const fetchData = async () => {
            var rawDate = new Date(appointmentInfo?.timeSlots?.fromDate)
            setDate(rawDate?.toLocaleDateString("de-DE", { weekday: 'long', year: 'numeric', month: 'short', day: '2-digit' }))
            setTime(rawDate?.toLocaleTimeString("de-DE", { hour: '2-digit', minute: '2-digit', hour12: false }))
        }
        fetchData()
    }, [appointmentInfo.timeSlots.fromDate])

    return (
        <>
            <div className="userInfoContainer">
                <div className="intro">
                    <p>Liebe Patientin, Lieber Patient,</p>
                    <p>vielen Dank, dass Sie zur Behandlung in unsere Praxis kommen. </p><p>Bitte teilen Sie unserer Praxis die Änderungen Ihrer Kontaktdaten mit, die dem Datenschutz der Europäischen Union unterliegen, und von uns streng vertraulich behandelt werden.</p>

                    <p>Mit freundlichen Grüßen</p>
                    <p>Ihr Praxisteam</p>
                </div>
                <br />
                <br />
                <div className="appointment-info">
                    <h2>Ihr Termin bei Praxis {appointmentInfo?.managerAlias}</h2>
                    <h4>{date} um {time}</h4>
                    <h4> </h4>
                </div>
                <div className="modal-content-footer">
                    <button className="btn-blue shadow" onClick={() => { onOkClicked() }} >{t("userInfo_ok")}</button>
                    &nbsp;
                    <button className="btn-blue shadow" onClick={() => { onChangeAppointmentClicked() }} >{t("userInfo_edit_appointment")}</button>
                </div>
            </div>
        </>
    )
}

export default AppointmentInfoDetails
