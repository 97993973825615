import React from 'react'

const Avatar = ({path}) => {
    return (
        <div>
           <img className="avatar" src={path}></img>
        </div>
    )
}

export default Avatar
