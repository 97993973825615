import React from 'react'
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";
import { useState } from "react"
import Info from '../infoBox/Info'

const ManegerPassword = ({ phone, updateUserPassword, success, isUpdate }) => {

    const [password, setPassword] = useState({ "value": "", "isValid": false })
    const [code, setCode] = useState({ "value": "", "isValid": false })
    const [passwordConfirm, setPasswordConfirm] = useState({ "value": "", "isValid": false })
    const [email, setEmail] = useState({ "value": "", "isValid": false })
    const [username, setUsername] = useState({ "value": "", "isValid": false })
    const [profilePic, setProfilePic] = useState({ "value": "", "isValid": false })
    const { t } = useTranslation();
    //const nameRef = useRef('')

    const onsubmit = (e) => {
        e.preventDefault()
        console.log("submit" + phone)
        if (password.isValid && passwordConfirm.isValid && code.isValid && email.isValid) {
            let data = new FormData()
            data.append("password", password.value)
            data.append("passwordConfirm", passwordConfirm.value)
            data.append("phone", phone)
            data.append("verificationCode", code.value)
            data.append("email", email.value)
            data.append("logo", profilePic.value)
            data.append("username", username.value)

            updateUserPassword(data)

            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    }

    const validateInput = (e) => {
        const fieldName = e.target.name
        const next = e.target.nextSibling;
        var errorMsg = ""

        switch (fieldName) {
            case "profilePic":
                errorMsg = checkInput(e)
                setProfilePic({ "value": e.target.files[0], "isValid": errorMsg === "" ? true : false })
                break
            case "username":
                errorMsg = checkInput(e)
                setUsername({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "password":
                errorMsg = checkInput(e)
                setPassword({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "passwordConfirm":
                errorMsg = checkInput(e)
                setPasswordConfirm({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "email":
                errorMsg = checkInput(e)
                setEmail({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "code":
                errorMsg = checkInput(e)
                setCode({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
        }

        if (errorMsg !== "") {
            showHideError(e, true)
            console.log("<p>" + errorMsg + "</p>")
            next.innerHTML = "<p>" + errorMsg + "</p>"
        }
        else {
            showHideError(e, false)
        }
    }

    const showHideError = (e, isShow) => {
        const next = e.target.nextSibling;

        if (isShow) {

            next.classList.remove("hide")
            e.target.classList.add("input-error-border")
        }
        else {
            next.classList.add("hide")
            e.target.classList.remove("input-error-border")
        }
    }

    const checkInput = (e) => {
        if (e.target.value === "") {
            return t("empty_field_msg")
        }

        if (e.target.value !== "" && e.target.type === "email") {

            const re = /\S+@\S+\.\S+/
            if (!re.test(e.target.value)) {
                return t("invalid_email_msg")
            }
        }

        if (e.target.value !== "" && e.target.type === "number") {
            const re = /^\d+$/
            if (!re.test(e.target.value)) {
                return t("invalid_phone_msg")
            }
        }

        return ""
    }

    const getInfo = () => {
        if (success.isShow && success.success) {
            return <Info text={"Password updated successfully"} color={"#FFFFFF"} />
        }

        if (success.isShow && !success.success) {
            return <Info text={"The code is wrong or expired"} color={"#bbbbbb"} />
        }
        return <></>
    }

    return (
        <div className="registerContainer">
            <h1> Anmeldung</h1>
            {getInfo()}

            <form id="new-contact-form" className="register-form" onSubmit={onsubmit}>

                
                <div className="form-control ">
                    <label>{t("phone")}</label>
                    <input placeholder={t("phone")} type="text" name="phone" defaultValue={phone} disabled />
                    <div className="input-error hide">

                    </div>
                </div>

                <div className="form-control ">
                    <label>{t("verificationCode")}</label>
                    <input placeholder={t("verificationCode")} type="number" name="code" onChange={(e) => validateInput(e)} required />
                    <div className="input-error hide">

                    </div>
                </div>
                {isUpdate === false ?
                    <> <div className="form-control ">
                        <label>{t("name")}</label>
                        <input placeholder={t("name")} type="text" name="username" onChange={(e) => validateInput(e)} required />
                        <div className="input-error hide">

                        </div>
                    </div>
                        <div className="form-control ">
                            <label>{t("profilePic")}</label>
                            <input placeholder={t("profilePic")} type="file" name="profilePic" onChange={(e) => validateInput(e)} />
                            <div className="input-error hide">

                            </div>
                        </div></> : <></>
                }


                <div className="form-control ">
                    <label>Email</label>
                    <input placeholder="Email" type="email" name="email" onChange={(e) => validateInput(e)} required />
                    <div className="input-error hide">

                    </div>
                </div>
                <div className="form-control ">
                    <label>{t("password")}</label>
                    <input placeholder={t("password")} type="password" name="password" onChange={(e) => validateInput(e)} required />
                    <div className="input-error hide">

                    </div>
                </div>
                <div className="form-control ">
                    <label>{t("passwordConfrim")}</label>
                    <input placeholder={t("passwordConfrim")} type="password" name="passwordConfirm" onChange={(e) => validateInput(e)} required />
                    <div className="input-error hide">

                    </div>
                </div>


                <div className="form-control">
                    <button className="btn-blue shadow" type="submit"><FontAwesomeIcon icon={faSave}></FontAwesomeIcon> {t("save")}</button>
                </div>

            </form>
        </div >
    )
}

export default ManegerPassword
