import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import "./services/i18n";

//localStorage.setItem("apiPath","https://appointment.dev.cogniologic.com")
localStorage.setItem("apiPath","https://appointmentstore.cogniologic.com")

 //React Render
 ReactDOM.render(<App/>, document.getElementById('root'))
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
