import React from 'react'
import { useState } from "react"
import { faSave } from "@fortawesome/free-solid-svg-icons";
import "./Contact.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";

const NewContact = ({ addContact }) => {
    const { t } = useTranslation();
    const [name, setName] = useState({ "value": "", "isValid": false })
    const [postcode, setPostcode] = useState({ "value": "", "isValid": false })
    const [lastname, setLastname] = useState({ "value": "", "isValid": false })
    const [dateOfBirth, setDateOfBirth] = useState({ "value": "", "isValid": false })
    const [note, setNote] = useState({ "value": "", "isValid": false })
    const [phone, setPhone] = useState({ "value": "", "isValid": false })
    const [email, setEmail] = useState({ "value": "", "isValid": false })
    const [address, setAddress] = useState({ "value": "", "isValid": false })
    const onsubmit = (e) => {
        e.preventDefault()
        if (name.isValid && lastname.isValid && phone.isValid && dateOfBirth.isValid && email.isValid && address.isValid && postcode.isValid) {
            var parts = dateOfBirth.value.split('.');
            var dateLong = new Date(parts[2], parts[1] - 1, parts[0]);
            const managerData = JSON.parse(localStorage.getItem("managerData"))
            console.log("manager data:",managerData)
            addContact({
                "name": name.value,
                "lastName": lastname.value,
                "dateOfBirth": dateLong.getTime(),
                "note": note.value,
                "email": email.value,
                "phone": phone.value,
                "address": address.value,
                "managerId": managerData.id,
                "postalCode": postcode.value,
                "avatar" : "https://logo.cogniologic.com/user.png"
            })

        }
    }

    const validateInput = (e) => {
        const fieldName = e.target.name
        const next = e.target.nextSibling;
        var errorMsg = ""

        switch (fieldName) {
            case "name":
                errorMsg = checkInput(e)
                setName({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "lastname":
                errorMsg = checkInput(e)
                setLastname({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "dateOfBirth":
                errorMsg = checkInput(e)
                setDateOfBirth({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "email":
                errorMsg = checkInput(e)
                setEmail({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "phone":
                errorMsg = checkInput(e)
                setPhone({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "address":
                errorMsg = checkInput(e)
                setAddress({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "postcode":
                errorMsg = checkInput(e)
                setPostcode({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
        }

        if (errorMsg !== "") {
            showHideError(e, true)
            console.log("<p>" + errorMsg + "</p>")
            next.innerHTML = "<p>" + errorMsg + "</p>"
        }
        else {
            showHideError(e, false)
        }
    }

    const checkInput = (e) => {
        if (e.target.value === "") {
            return t("empty_field_msg")
        }

        if (e.target.value !== "" && e.target.type === "email") {

            const re = /\S+@\S+\.\S+/
            if (!re.test(e.target.value)) {
                return t("invalid_email_msg")
            }
        }

        if (e.target.value !== "" && e.target.type === "number") {
            const re = /^\d+$/
            const digits = e.target.value.toString().length
            if (!re.test(e.target.value) || digits > 11 ) {
                return t("invalid_phone_msg")
            }
        }
        return ""
    }

    const showHideError = (e, isShow) => {
        const next = e.target.nextSibling;
        if (isShow) {
            next.classList.remove("hide")
            e.target.classList.add("input-error-border")
        }
        else {
            next.classList.add("hide")
            e.target.classList.remove("input-error-border")
        }
    }

    return (
        <>
            <div className="panel-header">

                <div className="contact-item">

                </div>
            </div>
            <div className="new-contact">
                <form id="new-contact-form" className="form" onSubmit={onsubmit}>
                    <fieldset className="border-melow">
                        <legend>{t("add_new_contact_msg")}</legend>
                        <div className="form-control">
                            <label>{t("name")}</label>
                            <input placeholder={t("name")} name="name" type="text" onChange={(e) => validateInput(e)} required />
                            <div className="input-error hide">

                            </div>
                        </div>
                        <div className="form-control">
                            <label>{t("lastname")}</label>
                            <input placeholder={t("lastname")} name="lastname" type="text" onChange={(e) => validateInput(e)} required />
                            <div className="input-error hide">

                            </div>
                        </div>
                        <div className="form-control ">
                            <label>{t("date_of_birth")}</label>
                            <input placeholder="DD.MM.YYYY" name="dateOfBirth" type="text" onChange={(e) => validateInput(e)} required />
                            <div className="input-error hide">

                            </div>
                        </div>
                        <div className="form-control ">
                            <label>Email</label>
                            <input placeholder="Email" type="email" name="email" onChange={(e) => validateInput(e)} required />
                            <div className="input-error hide">

                            </div>
                        </div>
                        <div className="form-control ">
                            <label>{t("phone")}</label>
                            <input placeholder={t("phone")} type="number" name="phone" onChange={(e) => validateInput(e)} required />
                            <div className="input-error hide">

                            </div>
                        </div>
                        <div className="form-control ">
                            <label>Addresse</label>
                            <input placeholder="addresse" type="text" name="address" onChange={(e) => validateInput(e)} />
                            <div className="input-error hide">

                            </div>
                        </div>
                        <div className="form-control ">
                            <label>{t("postcode")}</label>
                            <input placeholder={t("postcode")} type="number" name="postcode" onChange={(e) => validateInput(e)} />
                            <div className="input-error hide">

                            </div>
                        </div>
                       
                        <div className="form-control ">
                            <label>{t("note")}</label>
                            <input placeholder={t("note")} type="text" name="note" onChange={(e) => setNote(e)} />
                        </div>
                      
                        <div className="form-control">
                            <button className="btn-blue shadow" type="submit"><FontAwesomeIcon icon={faSave}></FontAwesomeIcon> {t("save")}</button>
                        </div>

                    </fieldset>
                </form>
            </div>
        </>
    )
}

export default NewContact
