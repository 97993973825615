import Avatar from "../visual/Avatar"
import Title from "../visual/Title"
import { useTranslation } from "react-i18next";
const Contact = ({ contact, onContactClick }) => {
    const { t } = useTranslation();
    String.prototype.capitalize = function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
    }
    return (
        <div className={"contact-container appointment card-bottom-border " + (contact.selected === true && "contact-selected")} onClick={() => onContactClick(contact)}>
            <div className="avatar-container">
                <Avatar path={contact.avatar} />
            </div>
            <div className="column">
                { 
                  contact?.name === null || contact?.lastName === null   ? <Title text={t("unknown_contact") } />:    <Title text={contact?.name?.capitalize() + " " + contact?.lastName?.capitalize()} />
                }
             
                <div><p className="subTitle">{contact?.phone}</p></div>
            </div>
            <div className="date">
                <p></p>
            </div>
        </div>
    )
}

export default Contact
