import Timeslot from "./Timeslot"
import { useState} from "react"
import SetNewAppointmnet from "../contact/SetNewAppointmnet"

const Timeslots = ({ timeslots,  onOkClicked, isEdit }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null)
    console.log("timeSlots:"+JSON.stringify(timeslots))
    const onCloseClicked = () => {
        setIsOpen(!isOpen);
    }
    
    const onTimeslotClicked = (selectedTimeSlot) => {
        //console.log(selectedTimeSlot)
        setSelectedTimeSlot(selectedTimeSlot)
        onCloseClicked()

        // setTimeslot(
        //     timeslots.map(((timeslot) => timeslot.id === selectedTimeSlot.id ?
        //         { ...timeslot, isSelected: !timeslot.isSelected } : timeslot))
        // )
    }

    const onOkclickedMid = (data) =>{
        onCloseClicked()
        onOkClicked(data)
    }

    return (
        <div className="time-slots-container">
            {timeslots.length > 0 ? timeslots.map((timeslotObj) => (<Timeslot key={timeslotObj.id} timeslot={timeslotObj} onTimeslotClicked={onTimeslotClicked} />)): <p>Es ist keine freien Termine am heutigen Tag vorhanden</p>}
            <SetNewAppointmnet isOpen={isOpen} data={selectedTimeSlot} onCloseClicked={onCloseClicked} onOkClicked={onOkclickedMid} isEdit ={isEdit} />
        </div>
    )
}

export default Timeslots
