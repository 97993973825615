
import './Register.css'
import {useHistory} from 'react-router-dom'
import {useState} from "react"
import Phone from './Phone'
import ManegerPassword from './ManegerPassword'


const Register = () => {
    const apiPath = localStorage.getItem("apiPath")
    const updatePasswordUrl = apiPath + "/register/user/update"
    const postPhoneUrl = apiPath + "/register/phone"
    const [success, setSuccess] = useState({ isShow: false, success: false })
    const [isUpdate, setIsUpdate] = useState(false)
    const history = useHistory();
    const [phone, setPhone] = useState()
    const [isManagerinfoPage, setManagerinfoPage] = useState(false);


    const postPhoneNumber = async (phone) => {
        setPhone(phone)
        const requestObj = {
            'phone_number': phone
        }
        const response = await fetch(postPhoneUrl, {
            method: "POST",
            headers: {
                "content-type": "application/json",
                mode: 'no-cors'
            },
            body: JSON.stringify(requestObj)
        })
        setSuccess({ isShow: false, success: false })
        const responseJson = await response.json()
        console.log("server Response:" + JSON.stringify(responseJson))
        setIsUpdate(responseJson.userAlreadyExist)
        setManagerinfoPage(true)

    }

    const updateUserPassword = async (requestObj) => {
        const response = await fetch(updatePasswordUrl, {
            method: "POST",
            headers: {

                mode: 'no-cors'
            },
            body: requestObj
        })
        const responseJson = await response.json()
        console.log("server Response:" + responseJson.codeValid)
        setSuccess({ isShow: true, success: responseJson.codeValid })
        if (responseJson.codeValid) {
            history.push("/contact")
            window.location.reload()
        }
    }

    return (
        <>
            { isManagerinfoPage === false ? <Phone postPhoneNumber={postPhoneNumber} /> :
              <ManegerPassword phone={phone} updateUserPassword={updateUserPassword} success = {success} isUpdate = {isUpdate} />
            }
        </>
    )
}

export default Register
