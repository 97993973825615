import React from 'react'
const Footer = () => {
    
    return (
        <div className="footer">
            <div className="footer-header">
{/* 
                <div className="column-item" >
                    <h3>Eisteino</h3>
                </div>
                <div className="column-item">
                    <h3>{t("location")}</h3>
                </div>
                <div className="column-item">
                    <h3>{t("contact")}</h3>
                </div> */}

            </div>
            {/* <div className="footer-content">
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer molestie risus ut orci vestibulum dictum. Curabitur fermentum nec odio sed congue. In porta quam iaculis leo suscipit, in feugiat tortor varius. Proin dignissim mauris vitae ex vulputate dignissim. Phasellus consequat accumsan felis eu posuere. Nulla eleifend libero arcu, in rutrum mauris scelerisque sed. Proin pulvinar sapien eu molestie vehicula. Maecenas id efficitur enim. Aenean vestibulum efficitur sem, quis tincidunt velit. Aliquam eget facilisis ipsum. Sed ullamcorper aliquet est, a rutrum orci egestas quis. Sed libero massa, laoreet id lectus ac, sodales viverra tortor. Nam consequat mauris sit amet eros pretium, id auctor lectus pretium. Pellentesque et eleifend metus.
                </p>

            </div>
            <div className="footer-footer">
                <div><FontAwesomeIcon icon={faFacebook} /></div>
                <div><FontAwesomeIcon icon={faTwitter} /></div>
                <div><FontAwesomeIcon icon={faYoutube} /></div>
            </div>  */}
            <div className="footer-copyright">
                <p>	&#169; 2021 copyright: Einsteino</p>
            </div>

        </div>
    )
}

export default Footer
