import Avatar from "../visual/Avatar"
import Title from "../visual/Title"
import "./Task.css"
import { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTimes, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
const MTask = ({ Task, onTaskClick, apporoveTask, denyTask }) => {
    const { t } = useTranslation();
    //const date = (new Date(1616486038047)).toString("YYYY-MM-dd")
    const rawDate = new Date(Task.created)
    const date = rawDate.toLocaleDateString("de-DE", { year: 'numeric', month: 'short', day: '2-digit' })
    const time = rawDate.toLocaleTimeString("de-DE", { hour: '2-digit', minute: '2-digit', hour12: false })
    const [option, setOption] = useState(false)

    String.prototype.capitalize = function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    }

    const onMouseEnter = () => {
        setOption(true);
    }

    const onMouseLeave = () => {
        setOption(false);
    }

    const statusToClassName = () => {
        switch (Task?.status) {
            case 2:
                return "approve"
            case 3:
                return "deny"
            default:
                return ""
        }
    }

    return (
        <div className={"task card-bottom-border " + statusToClassName()} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} onClick={() => onTaskClick(Task)}>
            <div className="avatar-container">
                <Avatar path={Task?.contact?.avatar} />
            </div>

            <div className="column">
                {
                    Task?.contact?.name === null || Task?.contact?.lastName === null ? <Title text={t("unknown_contact")} /> : <Title text={Task?.contact?.name?.capitalize() + " " + Task?.contact?.lastName?.capitalize()} />}

                <div><p className="subTitle">{Task?.contact?.email}</p></div>
            </div>
            <div className="date">
                <p>{date}</p>
                <p>{time}</p>
            </div>
            {
                option === true && <div className="appointment-overlay">
                    <div className="task-overlay-row" onClick={(e) => apporoveTask(e, Task)} ><FontAwesomeIcon title={t("approve")} icon={faUserCheck} /></div>
                    <div className="task-overlay-row" onClick={(e) => denyTask(e, Task)}><FontAwesomeIcon title={t("deny")} icon={faUserTimes} /></div>
                </div>
            }
        </div>
    )
}

export default MTask
