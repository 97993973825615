import "./visual.css"
import { faInfoCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Warning({ title,content, type }) {
    return (

        <div className={type === "warning" ? "warning-container" : "info-container"} >
            <div className={type === "warning" ? "warning-header" : "info-header"}>
                {
                    type === "warning" ?
                        <FontAwesomeIcon icon={faExclamationTriangle} /> : <FontAwesomeIcon icon={faInfoCircle} />}
                &nbsp;{title}
            </div>
            <div className={type === "warning" ? "warning-content" : "info-content"}>
                <p>{content}</p>
            </div>
        </div>

    )
}

export default Warning
