
import { faCalendarDay, faPersonBooth, faSearch, faTasks, faThermometer, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BroadcastChannel } from 'broadcast-channel';
import firebase from 'firebase/app';
import 'firebase/messaging';
import jwt_decode from "jwt-decode";
import Keycloak from "keycloak-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Appointmnets from './appointment/Appointmnets';
import ContactDetails from './contact/ContactDetails';
import Contacts from './contact/contacts';
import NewContact from './contact/NewContact';
import Loader from "./loader/Loader";
import MTasks from "./task/MTasks";

const Main = ({ keycloak }) => {
    const { t } = useTranslation();
    const [contactLoading, setContactLoading] = useState(false)
    const [appointmnetLoading, setAppointmnetLoading] = useState(false)
    const [selectedContact, setSelectedContact] = useState(null)
    const [selectedAppointment, setSelectedAppointment] = useState(null)
    const location = useLocation();
    const history = useHistory();
    const [state, setstate] = useState({ "data": null })
    const [contacts, setContacts] = useState([])
    const [contactAppointments, setContactAppointments] = useState([])
    const [appointments, setAppointment] = useState([])
    const [watinigAppointments, setWatinigAppointments] = useState([])
    const [operationAppointments, setOperationAppointments] = useState([])
    const apiPath = localStorage.getItem("apiPath")
    const apiUrl = apiPath + "/v5/contact?managerId="
    const [tasks, setTasks] = useState([])
    const getManagerDataUrl = apiPath + "/user/manager?clientId="
    const getAppointmentsUrl = apiPath + "/v6/appointment/"
    const getContactByElementUrl = apiPath + "/v5/search/contact/"
    const getAppointmentByElementUrl = apiPath + "/v5/search/appointment/"
    const getTaskByElementUrl = apiPath + "/search/task/"
    const getContactAppointment = apiPath + "/v5/search/appointment/contact"

    const checkLogin = (response, keycloak) => {
        const status = response.status
        console.log("status:", status);
        if (status === 401 || status === "401") {
            //TODO ????    
        }
    }

    useEffect(() => {
        if (location.pathname === "/")
            onAppointmentIconClicked()
    })

    var firebaseConfig = {
        apiKey: "AIzaSyCUYE18ST8sDj31FnzGD0b6HP0YZhe-t50",
        authDomain: "einsteino-client.firebaseapp.com",
        projectId: "einsteino-client",
        storageBucket: "einsteino-client.appspot.com",
        messagingSenderId: "330446905021",
        appId: "1:330446905021:web:56358b3d4dcba6ffb661d4",
        measurementId: "G-96KE7SPMCK"
    };

    useEffect(() => {
        firebase.initializeApp(firebaseConfig);
        if (firebase.messaging.isSupported()) {
            const messaging = firebase.messaging();
            messaging
                .requestPermission()
                .then(() => messaging.getToken())
                .then((firebaseToken) => {
                    postFcmToken(firebaseToken)
                    console.log("firebase token:" + firebaseToken);
                })
                .catch((err) => {

                });

            messaging.onMessage((payload) => {
                if ((payload.data.type === "1000" || payload.data.type === 1000)) {
                    fetchAppointment(1)
                }
                if (payload.data.type === "1002" || payload.data.type === 1002) {
                    fetchTasks()
                }
                console.log("new msg recieved forground type:", payload)
            });
        }
    }, [])

    useEffect(() => {
        const broadcast = new BroadcastChannel('count-channel');
        // Listen to the response
        broadcast.onmessage = (event) => {
            console.log("notification type:", event.data.data.type);
            if (event.data.data.type === "1000" || event.data.data.type === 1000) {
                console.log("new msg recieved in background")
                fetchAppointment(1)
            }
            if (event.data.data.type === "1002" || event.data.data.type === 1002) {
                console.log("new msg recieved in background")
                fetchTasks()
            }
        };
    }, [])

    //waiting for token to become ready
    useEffect(() => {
        const broadcast = new BroadcastChannel('token-chanel');
        // Listen to the response
        broadcast.onmessage = (event) => {
            console.log("token ready");
            getManagerData()
        };
    }, [])

    const webTokenUrl = apiPath + "/v5/user/webtoken"

    const postFcmToken = async (fcmToken) => {
        const token = localStorage.getItem('react-token')
        // alert(fcmToken)
        console.log("token in app.js:" + token)
        if (token == null) {
            return
        }
        const data = new FormData()
        data.append("fcmToken", fcmToken)
        const response = await fetch(webTokenUrl
            , {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem('react-token')
                },
                body: data
            }
        )
        checkLogin(response, keycloak)
        // const responseJson = await response.json()
        //console.log("json:" + responseJson)
        return ""
    }

    const getManagerData = async () => {
        const token = localStorage.getItem('react-token');
        if (token === null) {
            //window.location.reload()
            return
        }
        const managerData = await fetchManagerData()
        localStorage.setItem("managerData", JSON.stringify(managerData))
        if (managerData !== "undefined" && managerData !== null)
            console.log("ManagerId:" + managerData.id)
        else {
            console.log("could not get manager data")
        }
        setContactLoading(true)
        await fetchContact(managerData.id)
        if (location.pathname === "/appointment")
            onAppointmentIconClicked()
        else if (location.pathname === "/waitingroom")
            onWatingroomIconClicked()
        else if (location.pathname === "/operationroom")
            onOperationroomIconClicked()
        else if (location.pathname === "/tasks")
            onTasksIconClicked()
    }

    const onSearchClicked = async (keyword) => {
        console.log("keyword:" + keyword)
        var responseJson = null
        const managerData = JSON.parse(localStorage.getItem("managerData"))

        if (location.pathname === "/contact") {
            if (keyword.length >= 3) {
                responseJson = await fetchSearchResult(getContactByElementUrl + managerData.id + "/?keyword=" + keyword)
                responseJson?.error !== undefined ? setContacts([]) : setContacts(responseJson)
            }
            else if (keyword.length === 0) {
                responseJson = await fetchContact(managerData.id)
                responseJson?.error !== undefined ? setContacts([]) : setContacts(responseJson)
            }
        }
        else if (location.pathname === "/appointment") {
            if (keyword.length >= 3) {
                responseJson = await fetchSearchResult(getAppointmentByElementUrl + managerData.id + "/?contactName=" + keyword + "&status=1")
                responseJson?.error !== undefined ? setAppointment([]) : setAppointment(responseJson)
            }
            else if (keyword.length === 0) {
                responseJson = await fetchAppointment(1)
                responseJson?.error !== undefined ? setAppointment([]) : setAppointment(responseJson)
            }
        }

        else if (location.pathname === "/waitingroom") {
            if (keyword.length >= 3) {
                responseJson = await fetchSearchResult(getAppointmentByElementUrl + managerData.id + "/?contactName=" + keyword + "&status=17")
                responseJson?.error !== undefined ? setWatinigAppointments([]) : setWatinigAppointments(responseJson)
            }
            else if (keyword.length === 0) {
                responseJson = await fetchAppointment(17)
                responseJson?.error !== undefined ? setWatinigAppointments([]) : setWatinigAppointments(responseJson)
            }
        }

        else if (location.pathname === "/operationroom") {
            if (keyword.length >= 3) {
                responseJson = await fetchSearchResult(getAppointmentByElementUrl + managerData.id + "/?contactName=" + keyword + "&status=18")
                responseJson?.error !== undefined ? setOperationAppointments([]) : setOperationAppointments(responseJson)
            }
            else if (keyword.length === 0) {
                responseJson = await fetchAppointment(18)
                responseJson?.error !== undefined ? setOperationAppointments([]) : setOperationAppointments(responseJson)
            }
        }

        else if (location.pathname === "/tasks") {
            if (keyword.length >= 3) {
                responseJson = await fetchSearchResult(getTaskByElementUrl + managerData.id + "/?keyword=" + keyword)
                responseJson?.error !== undefined ? setTasks([]) : setTasks(responseJson)
            }
            else if (keyword.length === 0) {
                fetchTasks()
            }
        }
    }

    const fetchSearchResult = async (url) => {
        const response = await fetch(url
            , {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem('react-token')
                }
            }
        )
        checkLogin(response, keycloak)
        const responseJson = await response.json()
        return responseJson
    }

    const fetchManagerData = async () => {
        const token = localStorage.getItem('react-token');
        console.log("token in main.js:" + token)
        const managerUsername = jwt_decode(token).preferred_username
        console.log("Manager username:", managerUsername)
        const response = await fetch(getManagerDataUrl + managerUsername
            , {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    "Authorization": "Bearer " + token
                }
            }
        )
        checkLogin(response, keycloak)
        const responseJson = await response.json()
        console.log("manager data from main:", responseJson)
        return responseJson
    }

    const fetchContact = async (managerId) => {
        const token = localStorage.getItem('react-token')
        const response = await fetch(apiUrl + managerId
            , {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    "Authorization": "Bearer " + token
                }
            }
        )
        checkLogin(response, keycloak)
        const responseJson = await response.json()
        setContactLoading(false)
        setContacts(responseJson)
        console.log("contact", responseJson)
        return responseJson
    }

    const fetchAppointment = async (status) => {

        var url = ""
        setAppointmnetLoading(true)
        const token = localStorage.getItem('react-token');
        const managerData = JSON.parse(localStorage.getItem("managerData"))
        if (status === 1) {

            url = getAppointmentsUrl + managerData.id;
        }

        else if (status === 17) {
            url = getAppointmentsUrl + managerData.id + "?status=17";
        }

        else if (status === 18) {
            url = getAppointmentsUrl + managerData.id + "?status=18";
        }

        console.log("url:", url)

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                "Authorization": "Bearer " + token,
            }
        })
        //checkLogin(response, keycloak)
        const responseJson = await response.json()
        console.log("appointments status:", responseJson)

        if (response.status === 400) {
            console.error("Error fetching appointments")
            // return
        }

        if (status === 1) {
            setAppointment(responseJson)
            setAppointmnetLoading(false)
            if (appointments?.length > 0) {
                appointmentclicked(appointments[0])
            }
        }

        else if (status === 17) {
            setWatinigAppointments(responseJson)
            setAppointmnetLoading(false)
            if (watinigAppointments?.length > 0) {
                appointmentclicked(watinigAppointments[0])
            }
        }

        else if (status === 18) {
            setOperationAppointments(responseJson)
            setAppointmnetLoading(false)
            if (operationAppointments?.length > 0) {
                appointmentclicked(operationAppointments[0])
            }
        }
        return responseJson
    }

    const addContact = async (contact) => {
        const token = localStorage.getItem('react-token')
        let method = ""
        var addContactUrl = apiPath + "/v5/contact"
        console.log("contact:", contact)
        if (typeof contact.id !== 'undefined' && contact.id !== null) {
            method = "PUT"
            addContactUrl = addContactUrl + "/" + contact.id
            // alert("update")
        }

        else {
            method = "POST"
            // alert("insert")
        }

        const response = await fetch(addContactUrl, {
            method: method,
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(contact)
        })
        checkLogin(response, keycloak)
        setSelectedContact(contact)
        checkLogin(response, keycloak)

        if (typeof contact.id !== 'undefined' || contact.id !== null) {
            setContacts(contacts.map((pcontact) => pcontact.id === contact.id ? contact : pcontact))
        }

        fetchContact(contact.managerId)

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        history.push("/contact")
    }

    const updateAppointment = async (newAppointment) => {
        console.log("updating appointmnet", newAppointment)
        setSelectedAppointment(newAppointment)
        setContactAppointments([...contactAppointments, newAppointment])
        //const appointment = await fetchAppointment(1)
        //setAppointment(appointment)
        //return history.push("/appointment")
    }

    const contactUpdateAppointment = (updatedAppointment, oldAppointment) => {
        console.log("old appointment:", oldAppointment)
        console.log("new appointment:", updatedAppointment)
        setContactAppointments(
            contactAppointments.map((appointment) => appointment.id === oldAppointment.id ? { ...appointment, id: updatedAppointment.id } : appointment))
        //setSelectedAppointment(newAppointment)
        //const appointment = await fetchAppointment()
        //setAppointment(appointment)
        //return history.push("/appointment")
    }

    const updateAppointmentStatus = (updatedAppointment) => {
        if (updatedAppointment.status === 1 || updatedAppointment.status === 7 || updatedAppointment.status === 8 || updatedAppointment.status === 17) {
            setAppointment(
                appointments.map((appointment) => appointment.id === updatedAppointment.id ? { ...appointment, status: updatedAppointment.status } : appointment))

            setContactAppointments(
                contactAppointments.map((appointment) => appointment.id === updatedAppointment.id ? { ...appointment, status: updatedAppointment.status } : appointment))
        }
        else if (updatedAppointment.status === 18) {
            setWatinigAppointments(
                watinigAppointments.map((appointment) => appointment.id === updatedAppointment.id ? { ...appointment, status: updatedAppointment.status } : appointment))
        }

        else if (updatedAppointment.status === 19) {
            setOperationAppointments(
                operationAppointments.map((appointment) => appointment.id === updatedAppointment.id ? { ...appointment, status: updatedAppointment.status } : appointment))
        }

        else if (updatedAppointment.status === 9) {

            setContactAppointments(
                contactAppointments.map((appointment) => appointment.id === updatedAppointment.id ? { ...appointment, status: updatedAppointment.status } : appointment))
        }
        console.log("status update appointment id:" + updatedAppointment.id)
    }

    const updatetaskstatus = (updatedTask) => {
        setTasks(
            tasks.map((task) => task.id === updatedTask.id ? { ...task, status: updatedTask.status } : task))
    }

    const appointmentclicked = async (appointment) => {
        setSelectedContact(appointment.contact)
        const contactAppointments = await fetchContactAppointment(appointment.contact)
        setContactAppointments(contactAppointments)
        console.log("updating contact appointments: " + JSON.stringify(contactAppointments))
        console.log(selectedContact)
    }

    const onContactIconClicked = () => {
        if (contacts.length > 0) {
            onContactClicked(contacts[0])
        }
        return history.push("/contact")
    }

    const onWatingroomIconClicked = () => {
        console.log("on waiting room icon clicked")
        fetchAppointment(17)
        return history.push("/waitingroom")
    }

    const onOperationroomIconClicked = () => {
        console.log("on operation icon clicked")
        fetchAppointment(18)
        return history.push("/operationroom")
    }

    const fetchTasks = async () => {
        const url = apiPath + "/task/"
        const token = localStorage.getItem('react-token');
        const managerData = JSON.parse(localStorage.getItem("managerData"))
        const response = await fetch(url + managerData.id
            , {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    "Authorization": "Bearer " + token
                }
            }
        )
        checkLogin(response, keycloak)
        const responseJson = await response.json()
        console.log("manager data from main:", responseJson)
        setTasks(responseJson)
    }

    const onTasksIconClicked = () => {
        console.log("on task icon clicked")
        fetchTasks()
        return history.push("/tasks")
    }

    const onAppointmentIconClicked = () => {
        console.log("on appointment icon clicked")
        fetchAppointment(1)
        return history.push("/appointment")
    }

    const onTaskClick = (task) => {
        onContactClicked(task.contact)
    }

    const onContactClicked = async (contact) => {
        setSelectedContact(contact)
        const contactAppointments = await fetchContactAppointment(contact)
        setContactAppointments(contactAppointments)
        console.log("updating contact appointments: " + JSON.stringify(contactAppointments))
        console.log(selectedContact)
        history.push("/contact")
    }

    const onNewContactClicked = () => {
        history.push("/newContact")
    }

    const fetchContactAppointment = async (contact) => {
        console.log("contact:", contact)
        const managerData = JSON.parse(localStorage.getItem("managerData"))
        const token = localStorage.getItem('react-token');
        const response = await fetch(getContactAppointment + "/" + managerData.id + "?contactId=" + contact.id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                "Authorization": "Bearer " + token
            }
        })
        checkLogin(response, keycloak)
        const responseJson = await response.json()

        return responseJson
    }

    return (

        <div className="main">
            <div className="leftPanel">
                <div className="panel-header">
                    <div className="search-container">
                        <input name="search" id="search" type="text" placeholder={t("search_msg")} onChange={(e) => onSearchClicked(e.target.value)} />
                        <div className="search-icon" onClick={onSearchClicked}><FontAwesomeIcon icon={faSearch} /></div>
                    </div>
                    <div className="tab-menu">

                        <div className="tab-menu-item" onClick={onContactIconClicked}>
                            <FontAwesomeIcon className={"tab-menu-item-icon " + ((location.pathname === "/contact" || location.pathname === "/newContact") && "active")} size="1x" icon={faUserCircle} />
                            <p>{t("contact")}</p>
                        </div>
                        <div className="tab-menu-item " onClick={onAppointmentIconClicked}>
                            <FontAwesomeIcon className={"tab-menu-item-icon " + (location.pathname === "/appointment" && "active")} size="1x" icon={faCalendarDay} />
                            <p>{t("appointment")}</p>
                        </div>

                        <div className="tab-menu-item" onClick={onWatingroomIconClicked}>
                            <FontAwesomeIcon className={"tab-menu-item-icon " + (location.pathname === "/waitingroom" && "active")} size="1x" icon={faPersonBooth} />
                            <p>{t("waitingroom")}</p>
                        </div>

                        <div className="tab-menu-item" onClick={onOperationroomIconClicked}>
                            <FontAwesomeIcon className={"tab-menu-item-icon " + (location.pathname === "/operationroom" && "active")} size="1x" icon={faThermometer} />
                            <p>{t("operationroom")}</p>
                        </div>

                        <div className="tab-menu-item" onClick={onTasksIconClicked}>
                            <FontAwesomeIcon className={"tab-menu-item-icon " + (location.pathname === "/tasks" && "active")} size="1x" icon={faTasks} />
                            <p>{t("tasks")}</p>
                        </div>
                    </div>
                </div>
                <div className="scroll-panel">
                    <Switch>
                        <Route path={["/contact", "/newContact"]} render={(props) => (
                            <>
                                {contactLoading === true ? <Loader /> : <Contacts contacts={contacts} mainState={state} onContactClick={onContactClicked} onNewContactClick={onNewContactClicked} />}
                            </>
                        )
                        } />

                        <Route path={["/appointment"]} render={(props) => (
                            <>
                                {appointmnetLoading === true ? <Loader /> : <Appointmnets checkLogin={checkLogin} appointments={appointments} onAppointmentClick={appointmentclicked} updateAppointmentStatus={updateAppointmentStatus} menu="approve" keycloak={Keycloak} />}
                            </>
                        )

                        } />

                        <Route path={["/waitingroom"]} render={(props) => (
                            <>
                                {appointmnetLoading === true ? <Loader /> : <Appointmnets checkLogin={checkLogin} appointments={watinigAppointments} onAppointmentClick={appointmentclicked} updateAppointmentStatus={updateAppointmentStatus} menu="waitingRoom" keycloak={Keycloak} />}
                            </>
                        )

                        } />

                        <Route path={["/operationroom"]} render={(props) => (
                            <>
                                {appointmnetLoading === true ? <Loader /> : <Appointmnets checkLogin={checkLogin} appointments={operationAppointments} onAppointmentClick={appointmentclicked} updateAppointmentStatus={updateAppointmentStatus} menu="operationRoom" keycloak={Keycloak} />}
                            </>
                        )

                        } />

                        <Route path={["/tasks"]} render={(props) => (
                            <>
                                {appointmnetLoading === true ? <Loader /> : <MTasks checkLogin={checkLogin} tasks={tasks} onTaskClick={onTaskClick} updatetaskstatus={updatetaskstatus} keycloak={Keycloak} />}
                            </>
                        )

                        } />
                    </Switch>
                </div>
            </div>

            <div className="rightPanel">
                {/* {(location.pathname === "/appointment" || location.pathname === "/") && selectedAppointment != null ? <div><AppointmentDetails appointment={selectedAppointment} /></div> : ""} */}
                {(location.pathname === "/tasks" || location.pathname === "/contact" || location.pathname === "/appointment" || location.pathname === "/waitingroom" || location.pathname === "/operationroom") && selectedContact !== null ? <div><ContactDetails contactUpdateAppointment={contactUpdateAppointment} updateContact={addContact} appointment={contactAppointments} updateAppointment={updateAppointment} contact={selectedContact} updateAppointmentStatus={updateAppointmentStatus} /></div> : ""}
                {location.pathname === "/newContact" ? <div><NewContact addContact={addContact} /></div> : ""}
                {location.pathname === "/appointment" && selectedAppointment === null || (location.pathname === "/contact" && selectedContact === null) || (location.pathname === "/" && selectedAppointment === null) ?
                    <div className="panel-header"> <div className="contact-item">
                    </div>
                    </div>
                    : ""}
            </div>
        </div>
    )
}

export default Main
