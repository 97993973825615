import "./notfound.css"
const NotFound = () => {


    return (
        <>
            <div class="mainbox">
                <div class="err">4</div>
                <div class="far">0</div>
                <div class="err2">4</div>
                <div class="msg">Maybe this page moved?<br/>Got deleted? <br/>Is hiding out in quarantine?<br/> Never existed in the first place?<p>Let's go <a href="/contact">Login</a> and try from there.</p></div>
            </div>
        </>
    )
}

export default NotFound
