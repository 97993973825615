import AppointmentInfoDetails from "./AppointmentInfoDetails"
import EditContactAppointment from "./EditContactAppointment"
import EditContactInfo from "./EditContactInfo"
import { useState, useEffect } from "react"
import { useHistory, Route, Switch, useLocation } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import Info from '../infoBox/Info'

const UserContactInfo = () => {
    const location = useLocation()
    const history = useHistory();
    const [success, setSuccess] = useState({ isShow: false, success: false })
    const [fToken, setFToken] = useState({ "value": "", "isValid": false })
    const [appointmentInfo, setAppointmentInfo] = useState(null)
    const apiPath = localStorage.getItem("apiPath")
    const [button, setButton] = useState({ "value": "" })
    const { t } = useTranslation();
    const appointmentInfoUrl = apiPath + "/public/contact/info"
    const isSuccess = (success) => {
        if (success) {
            setSuccess({ isShow: true, success: true })
        }
        else {
            setSuccess({ isShow: true, success: false })
        }
    }

    useEffect(() => {
        console.log("getting ftoken")
        if (location.pathname.replace("/additionalInfo/", "") !== null
            && location.pathname.replace("/additionalInfo/", "") !== ""
        ) {
            const path = location.pathname.replace("/additionalInfo/", "")
            setFToken({ "value": path, "isValid": true })
            setSuccess({ isShow: false, success: false })
            //alert(fToken.value)
            //getAppointmentInfo()
        }
        else {
            setFToken({ "value": "", "isValid": false })
            setSuccess({ isShow: true, success: false })
        }

    }, [])

    useEffect(() => {
        const fetchData = async () => {
            console.log("ftoken changed")
            if (fToken.isValid) {
                const response = await getAppointmentInfo()
                if (response !== null) {
                    setAppointmentInfo(response)
                }
            }
        }
        fetchData()
    }, [fToken, button])

    const getAppointmentInfo = async () => {
        console.log("getting appointment data")
        const response = await fetch(appointmentInfoUrl + "?token=" + fToken.value, {
            method: "GET",
            headers: {
                "content-type": "application/json",
            },
        })
        if (response.status !== 200) {
            return null;
        }
        return await response.json()
    }

    const onChangeAppointmentClicked = () => {
        if (appointmentInfo !== null) {
            setButton({ "value": "onChangeAppointment" })
            console.log("appointment info :", appointmentInfo)
            //history.push("/additionalInfo/editAppointment")
        }
    }

    const onOkClicked = () => {
        if (appointmentInfo !== null && appointmentInfo.verified === false) {
            setButton({ "value": "editContact" })
            //history.push("/additionalInfo/editContact")
        }
        else {

        }
    }

    const route = () => {
        if (fToken.isValid && appointmentInfo != null) {
            return <AppointmentInfoDetails appointmentInfo={appointmentInfo} onChangeAppointmentClicked={onChangeAppointmentClicked} onOkClicked={onOkClicked} fToken={fToken} />
        }
        return <div className="userInfoContainer">
            <Info text={t("form_submit_failed")} color={"#DC143C"} textColor={"white"} />
        </div>
    }

    return (
        <div>
            <>
                {button.value === "editContact" && <EditContactInfo isEdit={appointmentInfo.verified} fToken={fToken} isSuccess={isSuccess} success={success} />}
            </>
            <>
                {button.value === "onChangeAppointment" && <EditContactAppointment setButton={setButton} fToken={fToken} appointmentInfo={appointmentInfo} />}
            </>
            <>
                {button.value === "" && route()}
            </>
        </div>
    )
}

export default UserContactInfo
