import React from 'react'
import logo from "../logo.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useEffect } from "react"
import { BroadcastChannel } from 'broadcast-channel';
import {useLocation} from "react-router-dom"

const Header = ({ keycloak, initOptions }) => {

    const { t } = useTranslation();
    const location = useLocation()
    const logout = () => {
        keycloak.logout()
        localStorage.removeItem("react-token")
        localStorage.removeItem("react-refresh-token")
        localStorage.removeItem("apiPath")
    }
    const broadcast = new BroadcastChannel('token-chanel');

    useEffect(() => {
        console.log("location from header:" + location.pathname)
        if (location.pathname === "/" || location.pathname === "/contact" || location.pathname === "/newContact" ||
            location.pathname === "/appointment" || location.pathname === "/waitingroom" || location.pathname === "/operationroom"
            || location.pathname === "/tasks"

        ) {
            keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
                if (!auth) {
                    window.location.reload()
                } else {
                    console.info("Authenticated")
                }

                broadcast.postMessage("token ready");
                localStorage.setItem("react-token", keycloak.token)
                localStorage.setItem("react-refresh-token", keycloak.refreshToken)
                setInterval(() => {
                    localStorage.setItem("react-token", keycloak.token)
                    localStorage.setItem("react-refresh-token", keycloak.refreshToken)
                    keycloak.updateToken(4).then((refreshed) => {
                        if (refreshed) {
                            console.info('Token refreshed:' + refreshed);
                        } else {
                            console.warn('Token not refreshed, valid for '
                                + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
                        }
                    }).catch(() => {

                        console.error('Failed to refresh token');
                    });
                }, 30000)

            }).catch(() => {
                console.error("Authenticated Failed");
            });
        }
    }, [])

    return (
        <div className="header" >
            <div className="header-logo">
                <img src={logo} alt="logo" width="50px" height="50px" />

            </div>
            { !location.pathname.includes("additionalInfo") && <div className="header-nav" onClick={logout}>
                <div><FontAwesomeIcon icon={faSignOutAlt} /></div>
                <div>{t("logout")}</div>
            </div>
            }
        </div>
    )
}

export default Header