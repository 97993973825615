import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Header from './components/Header'
import Footer from './components/Footer'
import Main from './components/Main'
import Login from './components/login/Login'
import register from "./components/register/Register"
import * as Keycloak from 'keycloak-js'
import NotFound from "./components/notFound/NotFound"
import UserContactInfo from "./components/userAdditionalInfo/UserContactInfo"

const AppRouter = () => {
    //keycloak init options
    let initOptions = {
        url: 'https://sso.cogniologic.com/auth',
        realm: 'cogniologic',
        clientId: 'einsteino',
        onLoad: 'login-required',
        "enable-cors": true
    }

    let keycloak = Keycloak(initOptions);
        
    return (
        <div className="container">
            <Router>
                <Header initOptions ={initOptions} keycloak={keycloak} />
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/register" component={register} />
                    <Route exact path={["/additionalInfo/*"]} component={UserContactInfo} />
                    <Route exact path={["/", "/contact", "/appointment", "/newContact","/waitingroom","/operationroom", "/tasks"]} render ={(props) =>(
                        <Main keycloak ={keycloak}/>
                    )
                    }  />
                    <Route  component={NotFound}/>
                </Switch>
                <Footer />
            </Router>
        </div>
    )
}

export default AppRouter
