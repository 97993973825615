import "./Timeslot.css"
import {formatDate} from "../../utils/DateManuplation.js"
import { useTranslation } from "react-i18next";

const Timeslot = ({ timeslot, onTimeslotClicked }) => {
    const { t } = useTranslation()
    //console.log("timeslot object : " + timeslot.fromDate)
    let formatedDate = formatDate(timeslot, t)
    //console.log(formatedDate)
    return (
        <div className="timeslot card-round shadow" onClick={() => onTimeslotClicked(timeslot)}>
            {/* <div className="tdate"><p>{formatedDate[0]}</p></div> */}
            <div className="ttime"><p>{formatedDate[1]}</p></div>

        </div>
    )
}

export default Timeslot
