import './App.css'
import AppRouter from './AppRouter'
function App({ keycloak }) {
 
  return (
    <AppRouter keycloak={keycloak} />
  )
}

export default App;
