import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import de from 'date-fns/locale/de';
import DetailedTimeslot from "../timeslot/DetailedTimeslot";
import SetNewAppointmnet from "../contact/SetNewAppointmnet"
import { useHistory } from 'react-router-dom'

import "react-datepicker/dist/react-datepicker.css";
const EditContactAppointment = ({ setButton, fToken, appointmentInfo }) => {
    const apiPath = localStorage.getItem("apiPath")
    const appointmentInfoUrl = apiPath + "/v6/appointment/available"
    const appointmentUpdateUrl = apiPath + "/v7/appointment/transcaction"
    const [timeslots, settimeslots] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null)
    const history = useHistory();
    registerLocale('de', de)
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(appointmentInfo ? new Date(appointmentInfo?.timeSlots?.fromDate) : null);
    const onOkClicked = async () => {
        const response = await fetch(appointmentUpdateUrl + "/" + selectedTimeSlot.id + "/" + appointmentInfo.appointmentId
            , {
                method: 'PUT',
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + appointmentInfo.token
                },
                body: JSON.stringify(appointmentInfo)
            })
        console.log("appointment", appointmentInfo)
        const responseJson = await response.json()
        if (response.status === 200) {
            setButton({ "value": "" })
        }
    }

    useEffect(() => {
        if (appointmentInfo !== null)
            onDateSelect(new Date(appointmentInfo?.timeSlots?.fromDate))
    }, [])

    const fetchAvaiableAppointment = async (date) => {
        var dateLong = new Date(date)
        //set all times to midnight 00:00
        dateLong.setHours(0, 0, 0, 0);
        const response = await fetch(appointmentInfoUrl + "?managerId=" + appointmentInfo.managerId
            + "&userId=" + appointmentInfo.contactId
            + "&duration=30"
            + "&date=" + dateLong.getTime()
            + "&zone=" + "Europe/Berlin"
            + "&count=" + "3"
            + "&offset=0"
            + "&client=phone"
            + "&gapTime=" + "120"
            , {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    "Authorization": "Bearer " + appointmentInfo.token
                }
            }
        )
        console.log("token:", appointmentInfo.token)
        const responseJson = await response.json()
        console.log("avaiable dates:", responseJson)
        return responseJson
    }

    const onDateSelect = async (selectedDate) => {
        console.log("appointment info :", appointmentInfo)
        setStartDate(selectedDate)
        const response = await fetchAvaiableAppointment(selectedDate);
        settimeslots(response)
    }

    const onCloseClicked = () => {
        setIsOpen(!isOpen);
    }

    const onTimeslotClicked = (selectedTimeSlot) => {
        //console.log(selectedTimeSlot)
        setSelectedTimeSlot(selectedTimeSlot)
        onCloseClicked()

        // setTimeslot(
        //     timeslots.map(((timeslot) => timeslot.id === selectedTimeSlot.id ?
        //         { ...timeslot, isSelected: !timeslot.isSelected } : timeslot))
        // )
    }

    const onOkclickedMid = (data) => {
        onCloseClicked()
        onOkClicked(data)
    }

    return (
        <div>
            <div className="userInfoContainer">
                <div className="intro">
                    <p>Liebe Patientin, Lieber Patient,</p>
                    <p>vielen Dank, dass Sie zur Behandlung in unsere Praxis kommen. </p><p>Bitte teilen Sie unserer Praxis die Änderungen Ihrer Kontaktdaten mit, die dem Datenschutz der Europäischen Union unterliegen, und von uns streng vertraulich behandelt werden.</p>

                    <p>Mit freundlichen Grüßen</p>
                    <p>Ihr Praxisteam</p>
                </div>

                <div className="userInfo-form">
                    <div className="form-control">
                        <label>{t("select_edit_appointment_date")}</label>
                        <DatePicker
                            showPopperArrow={false}
                            dateFormat="dd.MM.yy"
                            minDate={new Date()}
                            selected={startDate}
                            onChange={(selectedDate) => onDateSelect(selectedDate)}
                            locale="de"
                            placeholderText={t("select_date_msg")}
                        />
                        <div className="input-error hide">

                        </div>
                    </div>
                </div>

                {timeslots.length > 0 ? timeslots.map((timeslotObj) => (<DetailedTimeslot key={timeslotObj.id} timeslot={timeslotObj} onTimeslotClicked={onTimeslotClicked} />)) : <p>Es ist keine freien Termine am heutigen Tag vorhanden</p>}
                <SetNewAppointmnet isOpen={isOpen} data={selectedTimeSlot} onCloseClicked={onCloseClicked} onOkClicked={onOkclickedMid} isEdit={true} />

                {/* <div className="modal-content-footer">
                    <button className="btn-blue shadow" onClick={() => { onOkClicked() }} >{t("ontact_edit_appointment_ok")}</button>
                </div> */}
            </div>
        </div>
    )
}

export default EditContactAppointment
