import "./Timeslot.css"
import { formatDateDetailed } from "../../utils/DateManuplation.js"
import { useTranslation } from "react-i18next";

const DetailedTimeslot = ({ timeslot, onTimeslotClicked }) => {
    const { t } = useTranslation()
    //console.log("timeslot object : " + timeslot.fromDate)
    let formatedDate = formatDateDetailed(timeslot, t)
    //console.log(formatedDate)
    return (
        <div className="detailed-timeslot card-min-round shadow" onClick={() => onTimeslotClicked(timeslot)}>
            <div className="tdate"><p>{formatedDate[0]}</p></div>
            <div className="ttime"><p>{formatedDate[1]}</p></div>
        </div>
    )
}

export default DetailedTimeslot
