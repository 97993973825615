import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";
import { useState } from "react"
import { faSms } from "@fortawesome/free-solid-svg-icons";

const Phone = ({ postPhoneNumber }) => {

    const { t } = useTranslation();
    const [phone, setPhone] = useState({ "value": "", "isValid": false })

    const onsubmit = (e) => {
        e.preventDefault()
        if (phone.isValid) {
            postPhoneNumber("+49" + phone.value);
        }
    }

    const validateInput = (e) => {
        const fieldName = e.target.name
        const next = e.target.nextSibling;
        var errorMsg = ""

        switch (fieldName) {
            case "phone":
                errorMsg = checkInput(e)
                setPhone({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
        }

        if (errorMsg !== "") {
            showHideError(e, true)
            console.log("<p>" + errorMsg + "</p>")
            next.innerHTML = "<p>" + errorMsg + "</p>"
        }
        else {
            showHideError(e, false)
        }
    }

    const showHideError = (e, isShow) => {
        const next = e.target.nextSibling;

        if (isShow) {

            next.classList.remove("hide")
            e.target.classList.add("input-error-border")
        }
        else {
            next.classList.add("hide")
            e.target.classList.remove("input-error-border")
        }
    }

    const checkInput = (e) => {
        if (e.target.value === "") {
            return t("empty_field_msg")
        }
        if (e.target.value !== "" && e.target.type === "number") {
            const re = /^\d+$/
            if (!re.test(e.target.value)) {
                return t("invalid_phone_msg")
            }
        }

        return ""
    }


    return (
        <div className="phoneContainer">
            <form id="new-contact-form" className="register-form" onSubmit={onsubmit}>
                <div className="form-control ">
                    <label>{t("getCodeSms")}</label>
                    <div className="form-input-container">
                        <div className="form-input-container-item-small">
                            <input type="text" value="+49" disabled />
                        </div>
                        <div className=" form-input-container-item-big">
                            <input placeholder={t("phone")} type="number" name="phone" onChange={(e) => validateInput(e)} required />

                            <div className="input-error hide">

                            </div>
                        </div>
                    </div>

                </div>


                <div className="form-control">
                    <button className="btn-blue shadow" type="submit"><FontAwesomeIcon icon={faSms}></FontAwesomeIcon> {t("sms")}</button>
                </div>

            </form>

        </div>
    )
}

export default Phone
