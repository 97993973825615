import Appointmnet from "./Appointmnet"
import { useLocation } from 'react-router-dom'
import "./Appointment.css"

const Appointmnets = ({ checkLogin,appointments, onAppointmentClick, updateAppointmentStatus, menu, keycloak }) => {
    const location = useLocation();
    const apiPath = localStorage.getItem("apiPath")
    const appointmentStatusUrl = apiPath + "/v6/appointment/"
    const updateStatus = async (appointment) => {
        const token = localStorage.getItem('react-token')
        const response = await fetch(appointmentStatusUrl, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(appointment)
        })
        checkLogin(response, keycloak)
        //setContacts([...contacts, contact])
        updateAppointmentStatus(appointment)
    }

    const approveAppointment = (e, appointment) => {
        e.stopPropagation();
        console.log(appointment)
        const updatedAppointment = {...appointment,status:7}
        console.log("updated appointment:",updatedAppointment)
        updateStatus(updatedAppointment)
    }

    const denyAppointment = (e, appointment) => {
        e.stopPropagation();
        console.log(appointment)
        const updatedAppointment = {...appointment,status:8}
        console.log("updated appointment:",updatedAppointment)
        updateStatus(updatedAppointment)

    }

    const sendToWaitingRoom = (e, appointment) => {
        e.stopPropagation();
        console.log(appointment)
        const updatedAppointment = {...appointment,status:17}
        console.log("updated appointment:",updatedAppointment)
        updateStatus(updatedAppointment)

    }

    const sendToOperationRoom = (e, appointment) => {
        e.stopPropagation();
        console.log(appointment)
        const updatedAppointment = {...appointment,status:18}
        console.log("updated appointment:",updatedAppointment)
        updateStatus(updatedAppointment)
    }

    const statusDone = (e, appointment) => {
        e.stopPropagation();
        console.log(appointment)
        const updatedAppointment = {...appointment,status:19}
        console.log("updated appointment:",updatedAppointment)
        updateStatus(updatedAppointment)
    }

    return (
        <>
            { appointments !==null ? appointments.filter((appointment) => {
                if(location.pathname ==="/appointment")
                return appointment.status !== 17
                if(location.pathname ==="/waitingroom")
                return appointment.status !== 18
                if(location.pathname ==="/operationroom")
                return appointment.status !== 19
            
            }).map((appointment) => (<Appointmnet key={appointment.id} appointment={appointment} onAppointmentClick={onAppointmentClick} apporoveAppointment={approveAppointment} denyAppointment={denyAppointment} sendToWatingRoom ={sendToWaitingRoom} sendToOperationRoom ={sendToOperationRoom} statusDone ={statusDone} menu ={menu} />) ) : "no appointment"
            
            }
        </>
    )
}

export default Appointmnets