import Modal from "react-modal"
import Calendar from 'react-calendar';
import { useState, useEffect } from "react"
import Loader from "../loader/Loader"
import Timeslots from '../timeslot/Timeslots';
import { useTranslation } from "react-i18next";
import { compareDates } from "../../utils/DateManuplation.js"

const EditAppointment = ({ isOpen, onCloseClicked, appointment, contact, updateAppointment }) => {
    var managerData = null
    const { t } = useTranslation();
    const DATA_LENGTH = 10
    var offset = 0
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const apiPath = localStorage.getItem("apiPath")
    const unavailableDaysUrl = apiPath + "/v5/appointment/month/unavailable"
        + "?zone=" + timeZone
        + "&duration=" + 30;
    const timeslotsUrl = apiPath + "/v5/appointment/available/"
        + "?count=" + DATA_LENGTH + "&zone=" + timeZone
        + "&offset=" + DATA_LENGTH * (offset++) + "&duration=" + 30;
    const setAppointmentUrl = apiPath + "/v5/appointment"
    const [timeslotsLodaing, setTimeslotsLodaing] = useState(false)
    const [timeslots, settimeslots] = useState([])
    const [unavaiableDays, setUnavailableDays] = useState([])
    const [clickedDate, setClickedDates] = useState()
    const [date, setDate] = useState()
    const [time, setTime] = useState()
    console.log("appointment:" + JSON.stringify(appointment))

    useEffect(async () => {
        if (appointment !== null && isOpen === true) {
            var rawDate = new Date(appointment.timeSlots.fromDate)
            const response = await fetchUnavailableDays(appointment.timeSlots.fromDate)
            setUnavailableDays(response);
            setClickedDates(rawDate)
            setDate(rawDate.toLocaleDateString("de-DE", { year: 'numeric', month: 'short', day: '2-digit' }))
            setTime(rawDate.toLocaleTimeString("de-DE", { hour: '2-digit', minute: '2-digit', hour12: false }))
            onDayClicked(rawDate)
        }
    }, [appointment, isOpen])

    const tileDisabled = ({ activeStartDate, date, view }) => {
        var dateLong = new Date(date)
        //console.log("date:", dateLong.getDate())
        for (var i = 0; i < unavaiableDays.length; i++) {
            if (compareDates(dateLong, new Date(unavaiableDays[i]))) {
                //console.log("date is disabled", dateLong)
                return true
            }
        }
        return false
    }

    const onMonthClicked = async ({ activeStartDate, value, view }) => {
        console.log("date:", activeStartDate)
        var dateLong = new Date(activeStartDate).getTime()
        const response = await fetchUnavailableDays(dateLong)
        setUnavailableDays(response);
        console.log("unavailable days:", unavaiableDays)
    }

    const fetchUnavailableDays = async (dateLong) => {
        managerData = JSON.parse(localStorage.getItem("managerData"))
        console.log("managerData from contact details:", managerData)
        const token = localStorage.getItem('react-token');
        const response = await fetch(unavailableDaysUrl + "&managerId=" + managerData.id + "&date=" + dateLong, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                "Authorization": "Bearer " + token
            }
        })
        const responseJson = await response.json()

        return responseJson
    }

    const onOkClicked = async (selectedTimeSlot) => {
        const token = localStorage.getItem('react-token')
        appointment.timeSlots = selectedTimeSlot
        console.log("appointment to update:",appointment)
        const response = await fetch(setAppointmentUrl, {
            method: "POST",
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(appointment)
        })
        const updatedAppointment = await response.json()
        updateAppointment(updatedAppointment, appointment)
        onCloseClicked()
    }

    const onDayClicked = async (value) => {
        setTimeslotsLodaing(true)
        var dateLong = new Date(value).getTime()
        const timeslots = await fetchTimeslots(dateLong)
        setTimeslotsLodaing(false)
        console.log("json:" + JSON.stringify(timeslots))
        console.log("error:" + timeslots.error)
        if (timeslots.error === undefined) {
            settimeslots(timeslots)
        }
        else {
            console.error(timeslots.error)
            settimeslots([])
        }
    }

    const fetchTimeslots = async (dateLong) => {
        managerData = JSON.parse(localStorage.getItem("managerData"))
        console.log("managerData from contact details:", managerData)
        const token = localStorage.getItem('react-token');
        const response = await fetch(timeslotsUrl + "&managerId=" + managerData.id + "&date=" + dateLong, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                "Authorization": "Bearer " + token
            }
        })
        const responseJson = await response.json()
        return responseJson
    }

    return (
        <Modal
            isOpen={isOpen}
            className="modal-box"
            overlayClassName="modal-overlay"
            onRequestClose={onCloseClicked}
            contentLabel="this is a test">
            <div className="modal-container">
                <div className="modal-title"><h4> {t("edit_date_msg")}</h4></div>
                <div className="modal-title margin-top-5px">
                    <h5>Termin vom {date} um {time} Uhr ändern </h5>
                </div>
                <div className="modal-content margin-top-5px">
                    <div className="info card  margin-top-10px">
                        <div className="left">
                            <Calendar
                                onChange={onDayClicked}
                                value={clickedDate}
                                onActiveStartDateChange={onMonthClicked}
                                next2Label={null}
                                prev2Label={null}
                                minDate={new Date()}
                                tileDisabled={tileDisabled}
                                showNeighboringMonth={false}
                            />
                        </div>
                        <div className="right ">
                            {timeslotsLodaing === true ? <Loader /> : <Timeslots isEdit={true} updateAppointment={updateAppointment} contact={contact} timeslots={timeslots} onOkClicked={onOkClicked} />}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default EditAppointment
