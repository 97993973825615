import MTask from "./MTask"
import "./Task.css"

const MTasks = ({ checkLogin,tasks, onTaskClick, updatetaskstatus, keycloak }) => {
    console.log("tasks:",tasks)
    const apiPath = localStorage.getItem("apiPath")
    const taskstatusUrl = apiPath + "/task/"
    
    const updateStatus = async (task) => {
        const token = localStorage.getItem('react-token')
        const response = await fetch(taskstatusUrl, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(task)
        })
        checkLogin(response, keycloak)
        //setContacts([...contacts, contact])
        updatetaskstatus(task)
    }

    const approveTask = (e, Task) => {
        e.stopPropagation();
        console.log(Task)
        const updatedTask = {...Task,status:2}
        console.log("updated Task:",updatedTask)
        updateStatus(updatedTask)
    }

    const denyTask = (e, Task) => {
        e.stopPropagation();
        console.log(Task)
        const updatedTask = {...Task,status:3}
        console.log("updated Task:",updatedTask)
        updateStatus(updatedTask)

    }


    return (
        <>
            { tasks !==null ? tasks.map((Task) => (<MTask key={Task.id} Task={Task} onTaskClick={onTaskClick} apporoveTask={approveTask} denyTask={denyTask} />) ) : "no Task"
            
            }
        </>
    )
}

export default MTasks