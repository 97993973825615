import React, { useEffect } from 'react'
import { useState } from "react"
import { faSave } from "@fortawesome/free-solid-svg-icons";
import "./Contact.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";
import { formatDateV1 } from "../../utils/DateManuplation.js"

const UpdateContact = ({ updateContact, contact, onEditContactClicked }) => {
    const { t } = useTranslation();
    const [name, setName] = useState({ "value": "", "isValid": false })
    const [postcode, setPostcode] = useState({ "value": "", "isValid": false })
    const [lastname, setLastname] = useState({ "value": "", "isValid": false })
    const [dateOfBirth, setDateOfBirth] = useState({ "value": "", "isValid": false })
    const [note, setNote] = useState({ "value": "", "isValid": false })
    const [phone, setPhone] = useState({ "value": "", "isValid": false })
    const [email, setEmail] = useState({ "value": "", "isValid": false })
    const [address, setAddress] = useState({ "value": "", "isValid": false })
    const onsubmit = (e) => {
        e.preventDefault()
        if (name.isValid && lastname.isValid && phone.isValid && dateOfBirth.isValid && email.isValid && address.isValid && postcode.isValid) {
            var parts = dateOfBirth.value.split('.');
            var dateLong = new Date(parts[2], parts[1] - 1, parts[0]);
            const managerData = JSON.parse(localStorage.getItem("managerData"))
            updateContact({
                "id": contact.id,
                "name": name.value,
                "lastName": lastname.value,
                "dateOfBirth": dateLong.getTime(),
                "note": note.value,
                "email": email.value,
                "phone": phone.value,
                "address": address.value,
                "managerId": managerData.id,
                "postalCode": postcode.value,
                "avatar": "https://logo.cogniologic.com/user.png"
            })

        }
    }

    useEffect(() => {
        if (contact !== null) {
            setName({ "value": contact.name !== null ? contact.name : "", "isValid": contact.name !== null ? true : false })
            setLastname({ "value": contact.lastName !== null ? contact.lastName : "", "isValid": contact.lastName !== null ? true : false })
            setPhone({ "value": contact.phone !== null ? contact.phone : "", "isValid": contact.phone !== null ? true : false })
            setPostcode({ "value": contact.postalCode !== null ? contact.postalCode : "", "isValid": contact.postalCode !== null ? true : false })
            setNote({ "value": contact.note !== null ? contact.note : "", "isValid": contact.note !== null ? true : false })
            setAddress({ "value": contact.address !== null ? contact.address : "", "isValid": contact.address !== null ? true : false })
            setEmail({ "value": contact.email !== null ? contact.email : "", "isValid": contact.email !== null ? true : false })
            if (contact.dateOfBirth !== null) {
                let formatedDate = formatDateV1(contact.dateOfBirth, t)
                setDateOfBirth({ "value": formatedDate[0], "isValid": true })
                //console.log("date of brith", formatedDate[0]);
            }
        }
    }, [contact])

    const validateInput = (e) => {
        const fieldName = e.target.name
        const next = e.target.nextSibling;
        var errorMsg = ""

        switch (fieldName) {
            case "name":
                errorMsg = checkInput(e)
                setName({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "lastname":
                errorMsg = checkInput(e)
                setLastname({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "dateOfBirth":
                errorMsg = checkInput(e)
                setDateOfBirth({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "email":
                errorMsg = checkInput(e)
                setEmail({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "phone":
                errorMsg = checkInput(e)
                setPhone({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "address":
                errorMsg = checkInput(e)
                setAddress({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "postcode":
                errorMsg = checkInput(e)
                setPostcode({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
        }

        if (errorMsg !== "") {
            showHideError(e, true)
            console.log("<p>" + errorMsg + "</p>")
            next.innerHTML = "<p>" + errorMsg + "</p>"
        }
        else {
            showHideError(e, false)
        }
    }

    const checkInput = (e) => {
        if (e.target.value === "") {
            return t("empty_field_msg")
        }

        if (e.target.value !== "" && e.target.type === "email") {

            const re = /\S+@\S+\.\S+/
            if (!re.test(e.target.value)) {
                return t("invalid_email_msg")
            }
        }

        if (e.target.value !== "" && e.target.type === "number") {
            const re = /^\d+$/
            const digits = e.target.value.toString().length
            if (!re.test(e.target.value) || digits > 11) {
                return t("invalid_phone_msg")
            }
        }
        return ""
    }

    const showHideError = (e, isShow) => {
        const next = e.target.nextSibling;
        if (isShow) {

            next.classList.remove("hide")
            e.target.classList.add("input-error-border")
        }
        else {
            next.classList.add("hide")
            e.target.classList.remove("input-error-border")
        }
    }
 
    return (
        <>
            <div className="new-contact">
                <form id="new-contact-form" className="form" onSubmit={onsubmit}>
                    <fieldset className="border-melow">
                        <legend>{t("EditContactMsg")}</legend>
                        <div className="form-control">
                            <label>{t("name")}</label>
                            <input placeholder={t("name")} name="name" type="text" defaultValue={name.value} onChange={(e) => validateInput(e)} required />
                            <div className="input-error hide">

                            </div>
                        </div>
                        <div className="form-control">
                            <label>{t("lastname")}</label>
                            <input placeholder={t("lastname")} name="lastname" type="text" defaultValue={lastname.value} onChange={(e) => validateInput(e)} required />
                            <div className="input-error hide">

                            </div>
                        </div>
                        <div className="form-control ">
                            <label>{t("date_of_birth")}</label>
                            <input placeholder="DD.MM.YYYY" defaultValue={dateOfBirth.value} name="dateOfBirth" type="text" onChange={(e) => validateInput(e)} required />
                            <div className="input-error hide">

                            </div>
                        </div>
                        <div className="form-control ">
                            <label>Email</label>
                            <input placeholder="Email" type="email" name="email" defaultValue={email.value} onChange={(e) => validateInput(e)} required />
                            <div className="input-error hide">

                            </div>
                        </div>
                        <div className="form-control ">
                            <label>{t("phone")}</label>
                            <input placeholder={t("phone")} type="number" name="phone" defaultValue={phone.value} onChange={(e) => validateInput(e)} required />
                            <div className="input-error hide">

                            </div>
                        </div>
                        <div className="form-control ">
                            <label>Address</label>
                            <input placeholder="address" type="text" name="address" defaultValue={address.value} onChange={(e) => validateInput(e)} />
                            <div className="input-error hide">

                            </div>
                        </div>
                        <div className="form-control ">
                            <label>{t("postcode")}</label>
                            <input placeholder={t("postcode")} type="number" name="postcode" defaultValue={postcode.value} onChange={(e) => validateInput(e)} />
                            <div className="input-error hide">

                            </div>
                        </div>

                        <div className="form-control ">
                            <label>{t("note")}</label>
                            <input placeholder={t("note")} type="text" name="note" defaultValue={note.value} onChange={(e) => setNote(e)} />
                        </div>

                        <div className="form-control-row">
                            <button className="btn-blue shadow" type="submit"><FontAwesomeIcon icon={faSave}></FontAwesomeIcon> {t("save")}</button>
                            <button className="btn-red shadow" type="button" onClick={() => onEditContactClicked()}><FontAwesomeIcon icon={faSave}></FontAwesomeIcon> {t("cancel")}</button>
                        </div>

                    </fieldset>
                </form>
            </div>
        </>
    )
}

export default UpdateContact
