import i18next from "i18next";
import { initReactI18next } from "react-i18next";
// "Inline" English and Arabic translations. 
// We can localize to any language and any number of languages.
const resources = {
    en: {
        translation: {
            app_name: "English",
            edit_date_msg: "Edit appointment date",
            add_contact: "Add new contact",
            search_msg: "Search",
            confirm: "Confrim ",
            confirm_delete: "Delete",
            confirm_undo: "Undelete",
            cancel: "Cancel",
            confrim_date_selection_edit: "The appointment is changed as follows",
            confrim_date_selection_edit_change_button : "Change",
            confrim_date_selection_edit_cancel_button : "Cancel",
            confrim_date_selection: "Confirm date selection",
            confrim_date_selection_ok_button : "Add",
            confrim_date_selection_cancel_button : "Cancel",
            add_new_contact_msg: "Add new contact",
            name: "Name",
            lastname: "Lastname",
            phone: "Phone",
            address: "Address",
            date_of_birth: "Date of birth",
            note: "Note",
            email: "Email",
            save: "Save",
            logout: "Logout",
            empty_field_msg: "Field can not be empty",
            invalid_email_msg: "Please enter valid email address",
            invalid_phone_msg: "Please enter valid phone number",
            location: "Location",
            contact : "Contact",
            password : "Password",
            passwordConfrim :"password confrim",
            getCodeSms:"Get code by SMS",
            sms:"SMS",
            verificationCode: "Verification code",
            additional_info_title : "Additional msg",
            form_submit_success: "Information uploaded successfully",
            form_submit_failed: "Information uploaded successfully",
            postcode : "Postcode",
            next :"Next",
            today : "Today",
            tomorrow : "Tomorrow",
            approve : "Approve",
            deny:"Deny",
            done :"Behandlung beenden",
            smsSend :"Send SMS",
            EditContactMsg:"Edit Contact information",
            edit:"Edit",
            Warning:"Achtung",
            info : "Information",
            delete:"Delete",
            undo:"Undo",
            delete_appointment_msg:"Do you really want to delete the appointment?",
            Undo_appointment_delete_msg : "Do you really want to undo appointment delete?",
            set_appointment_msg:"Are you sure?",
            profilePic:"Your profile avatar",
            unknown_contact:"Unknown User",
            appointment:"Appointment",
            waitingroom:"Wating room",
            to_waitingroom:"Wating room",
            to_operationroom:"Operation room",
            operationroom:"Operation room",
            tasks:"Tasks",
            select_edit_appointment_date : "Bitte wählen Sie einen neuen Termin aus"
        },
    },
    de: {
        translation: {
            app_name: "German",
            edit_date_msg: "Termin bearbeiten",
            add_contact: "Neuer Kontakt hinzufügen",
            search_msg: "Suche",
            confirm: "Bestätigen",
            confirm_delete: "Absagen",
            confirm_undo: "Erneut Vergeben",
            cancel: "Abbrechen",
            confrim_date_selection: "Sie erstellen einen neuen Termin",
            confrim_date_selection_ok_button : "Erstellen",
            confrim_date_selection_cancel_button : "Abbrechen",
            confrim_date_selection_edit: "Der Termin wird wie folgt geändert",
            confrim_date_selection_edit_change_button : " Ändern",
            confrim_date_selection_edit_cancel_button : "Abbrechen",
            add_new_contact_msg: "Neuer Kontakt hinzufügen",
            name: "Vorname",
            lastname: "Nachname",
            phone: "Telefonnummer",
            address: "Adresse",
            date_of_birth: "Geburtsdatum",
            note: "Zusätzliche Angaben",
            email: "Email",
            save: "speichern",
            logout: "Ausloggen",
            empty_field_msg: "Feld darf nicht leer sein",
            invalid_email_msg: "Bitte geben Sie eine gültige Email Adresse an",
            invalid_phone_msg: "Bitte geben Sie eine gültige Telefonnummer an",
            location: "Ort",
            contact : "Kontakt",
            password : "Passwort",
            passwordConfrim :"Passwort bestätigen",
            getCodeSms:"Bitte geben Sie unten Ihre Handy Nummer ein. Sie erhalten anschliessend per SMS-Nachricht Ihren ​Verifizierungscode, die Sie in nächster Schritt eingeben müssen.",
            sms:"Schicke mir eine SMS",
            verificationCode: "​Verifizierungscode",
            additional_info_title : "Additional msg",
            form_submit_failed: "Die Datenübermittlung ist fehlgeschlagen.",
            form_submit_success: "Ihre Daten wurden erfolgreich übermittelt.",
            postcode : "Postleitzahl",
            next :"Weiter",
            today : "Heute",
            tomorrow : "Morgen",
            approve : "Patient*in ist anwesend",
            deny:"Zum Termin nicht erschienen",
            done :"Behandlung beenden",
            smsSend :"Schicke mir eine SMS",
            smsSend_delete :"Absagen & SMS Senden",
            smsSend_undo :"Vergeben & SMS Senden",
            EditContactMsg:"Kontaktdaten bearbeiten",
            edit:"Bearbeiten",
            warning:"Auchtung",
            info : "Information",
            delete:"Löschen",
            undo:"Rückgängig machen",
            delete_appointment_msg:"Möchten Sie den Termin löschen?",
            Undo_appointment_delete_msg : "Möchten Sie den Termin wiederherstellen?",
            set_appointment_msg:"Sind Sie sicher?",
            profilePic:"Ihr Bild",
            unknown_contact:"Unbekannt",
            appointment:"Termin",
            waitingroom:"Wartezimmer",
            operationroom:"Behandlungszimmer",
            to_waitingroom:"Ins Wartezimmer",
            to_operationroom:"Ins Behandlungszimmer",
            tasks:"Aufgaben",
            userInfo_ok:"Richtig so!",
            userInfo_edit_appointment:"Änderen",
            select_edit_appointment_date : "Bitte wählen Sie einen neuen Termin aus"
        },
    },
};
i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: "de",
        interpolation: {
            escapeValue: false,
        },
    });
export default i18next;