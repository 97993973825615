import "./Login.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { useState} from "react"


const Login = () => {

    const [username, setusername] = useState({ "value": "", "isValid": false })
    const [password, setpassword] = useState({ "value": "", "isValid": false })
    

  
    const validateInput = (e) => {
        const fieldName = e.target.name
        const next = e.target.nextSibling;
        var errorMsg = ""
        switch (fieldName) {
            case "username":
                errorMsg = checkInput(e)
                setusername({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "password":
                errorMsg = checkInput(e)
                setpassword({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
        }

        if (errorMsg !== "") {
            showHideError(e, true)
            console.log("<p>" + errorMsg + "</p>")
            next.innerHTML = "<p>" + errorMsg + "</p>"
        }
        else {
            showHideError(e, false)
        }
    }

    const checkInput = (e) => {
        const fieldName = e.target.name
        const next = e.target.nextSibling;
        if (e.target.value === "") {
            return "Field can not be empty"
        }
        return ""
    }

   

    const showHideError = (e, isShow) => {
        const fieldName = e.target.name
        const next = e.target.nextSibling;

        if (isShow) {

            next.classList.remove("hide")
            e.target.classList.add("input-error-border")
        }
        else {
            next.classList.add("hide")
            e.target.classList.remove("input-error-border")
        }
    }
    return (
        <div className="login">
            <form className="login-form">
                <div className="form-control">
                    <label>Username</label>
                    <input placeholder="username" name="username" type="text" onChange={(e) => validateInput(e)} required />
                    <div className="input-error hide">

                    </div>
                </div>
                <div className="form-control">
                    <label>Password</label>
                    <input placeholder="Password" name="password" type="text" onChange={(e) => validateInput(e)} required />
                    <div className="input-error hide">

                    </div>
                </div>
                <div className="form-control">
                    <button className="btn-blue shadow" ><FontAwesomeIcon icon={faSignInAlt}></FontAwesomeIcon> Login</button>
                </div>
            </form>
        </div>
    )
}

export default Login
