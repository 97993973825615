import Modal from "react-modal"
import { formatDate } from "../../utils/DateManuplation.js"
import { useTranslation } from "react-i18next";
import Warning from "../visual/Warning.js";

const SetNewAppointmnet = ({ isOpen, data, onCloseClicked, onOkClicked, isEdit }) => {
    const { t } = useTranslation();
    Modal.setAppElement("#root")
    let formatedDate = formatDate(data, t)
    const getStringDate = () => {
        if (formatedDate !== null) {
            return formatedDate[0] + " um " + formatedDate[1]
        }
        return ""
    }

    return (
        <Modal
            isOpen={isOpen}
            className="modal-box-small"
            overlayClassName="modal-overlay"
            onRequestClose={onCloseClicked}
            contentLabel="this is a test">
            <div className="modal-container">
                <div className="modal-title"><h5>{isEdit === true ? t("confrim_date_selection_edit") : t("confrim_date_selection")}</h5></div>
                <div className="modal-content margin-top-5px">
                    <Warning title={getStringDate()} content={t("set_appointment_msg")} type="info" />
                </div>
                <div className="modal-content-footer">
                    <button className="modal-item-btn-red" onClick={onCloseClicked}>{isEdit === true ? t("confrim_date_selection_edit_cancel_button") : t("confrim_date_selection_cancel_button")}</button>
                    <button className="modal-item-btn-blue" onClick={() => onOkClicked(data)}>{isEdit === true ? t("confrim_date_selection_edit_change_button") : t("confrim_date_selection_ok_button")}</button>
                </div>
            </div>
        </Modal>
    )
}

export default SetNewAppointmnet
