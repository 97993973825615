import { useState } from "react"
import "./userContactInfo.css"
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import Info from '../infoBox/Info'
import UserInfoUpdateConfrim from './UserInfoUpdateConfrim'
const EditContactInfo = ({ fToken, success, isSuccess }) => {
    const location = useLocation()
    const search = window.location.search;
    const query = new URLSearchParams(search);
    const { t } = useTranslation();
    const [name, setName] = useState({ "value": "", "isValid": false })
    const [lastname, setLastname] = useState({ "value": "", "isValid": false })
    const [dateOfBirth, setDateOfBirth] = useState({ "value": "", "isValid": false })
    const [note, setNote] = useState({ "value": "", "isValid": false })
    const [email, setEmail] = useState({ "value": "", "isValid": false })
    const [address, setAddress] = useState({ "value": "", "isValid": false })
    const [postcode, setPostcode] = useState({ "value": "", "isValid": false })
    const [appointment, setAppointment] = useState(null)
    const apiPath = localStorage.getItem("apiPath")
    //const nameRef = useRef('')

    const serverUrl = apiPath + "/public/v5/additionalContactInfo"
    const addAdditionalInfoContact = async (contact) => {
        const response = await fetch(serverUrl, {
            method: "POST",
            headers: {
                "content-type": "application/json",

            },
            body: JSON.stringify(contact)
        })

        if (response.status === 201) {
            isSuccess(true)
            const responseJson = await response.json()
            console.log(responseJson)
            setAppointment(responseJson)
            // alert(appointment.contact.name)
        }
        else {
            isSuccess(false)
        }

        console.log(response.status);
    }

    const onsubmit = (e) => {
        console.log("Ftoken:", fToken)
        console.log("location pathname:", location.pathname.replace("/", ""))
        e.preventDefault()

        if (name.isValid && lastname.isValid && fToken.isValid && dateOfBirth.isValid) {
            var parts = dateOfBirth.value.split('.');
            var dateLong = new Date(parts[2], parts[1] - 1, parts[0]);
            const managerData = JSON.parse(localStorage.getItem("managerData"))
            window.scroll(0, 0);
            addAdditionalInfoContact({
                "name": name.value,
                "lastName": lastname.value,
                "dateOfBirth": dateLong.getTime(),
                "note": note.value,
                "email": email.value,
                "fToken": fToken.value,
                "address": address.value,
                "postalCode": postcode.value
            })
        }
    }

    const validateInput = (e) => {
        const fieldName = e.target.name
        const next = e.target.nextSibling;
        var errorMsg = ""

        switch (fieldName) {
            case "name":
                errorMsg = checkInput(e)
                setName({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "lastname":
                errorMsg = checkInput(e)
                setLastname({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "dateOfBirth":
                errorMsg = checkInput(e)
                setDateOfBirth({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "email":
                errorMsg = checkInput(e)
                setEmail({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "address":
                errorMsg = checkInput(e)
                setAddress({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
            case "postcode":
                errorMsg = checkInput(e)
                setPostcode({ "value": e.target.value, "isValid": errorMsg === "" ? true : false })
                break
        }

        if (errorMsg !== "") {
            showHideError(e, true)
            console.log("<p>" + errorMsg + "</p>")
            next.innerHTML = "<p>" + errorMsg + "</p>"
        }
        else {
            showHideError(e, false)
        }
    }

    const checkInput = (e) => {
        const fieldName = e.target.name
        const next = e.target.nextSibling;
        if (e.target.value === "") {
            return t("empty_field_msg")
        }

        if (e.target.value !== "" && e.target.type === "email") {

            const re = /\S+@\S+\.\S+/
            if (!re.test(e.target.value)) {
                return t("invalid_email_msg")
            }
        }

        if (e.target.value !== "" && e.target.type === "number") {
            const re = /^\d+$/
            if (!re.test(e.target.value)) {
                return t("invalid_phone_msg")
            }
        }

        return ""
    }

    const showHideError = (e, isShow) => {
        const fieldName = e.target.name
        const next = e.target.nextSibling;

        if (isShow) {

            next.classList.remove("hide")
            e.target.classList.add("input-error-border")
        }
        else {
            next.classList.add("hide")
            e.target.classList.remove("input-error-border")
        }
    }

    const getInfo = () => {
        if (success.isShow && success.success) {
            return appointment === null ? <div className="userInfoContainer"><Info text={t("form_submit_success")} color={"#98FB98"} textColor={"black"} /> </div> :
                <UserInfoUpdateConfrim appointment={appointment} />
        }

        if (success.isShow && !success.success) {
            return <div className="userInfoContainer">
                <Info text={t("form_submit_failed")} color={"#DC143C"} textColor={"white"} />
            </div>
        }
        if (!success.isShow && !success.success) {

            return <div className="userInfoContainer">
                {/* <legend style={{ margin: "20px" }}>{t("additional_info_title")}</legend> */}
                <div className="intro">
                    <p>Liebe Patientin, Lieber Patient,</p>
                    <p>vielen Dank, dass Sie zur Behandlung in unsere Praxis kommen. </p><p>Bitte teilen Sie unserer Praxis die Änderungen Ihrer Kontaktdaten mit, die dem Datenschutz der Europäischen Union unterliegen, und von uns streng vertraulich behandelt werden.</p>

                    <p>Mit freundlichen Grüßen</p>
                    <p>Ihr Praxisteam</p>

                </div>

                <form id="new-contact-form" className="userInfo-form" onSubmit={onsubmit}>


                    <div className="form-control">
                        <label>{t("name")}*</label>
                        <input placeholder={t("name")} name="name" type="text" onChange={(e) => validateInput(e)} required />
                        <div className="input-error hide">

                        </div>
                    </div>
                    <div className="form-control">
                        <label>{t("lastname")}*</label>
                        <input placeholder={t("lastname")} name="lastname" type="text" onChange={(e) => validateInput(e)} required />
                        <div className="input-error hide">

                        </div>
                    </div>
                    <div className="form-control ">
                        <label>{t("date_of_birth")}*</label>
                        <input placeholder="DD.MM.YYYY" name="dateOfBirth" type="text" onChange={(e) => validateInput(e)} required />
                        <div className="input-error hide">

                        </div>
                    </div>
                    <div className="form-control ">
                        <label>Email</label>
                        <input placeholder="Email" type="email" name="email" onChange={(e) => validateInput(e)} />
                        <div className="input-error hide">

                        </div>
                    </div>
                    <div className="form-control ">
                        <label>Addresse</label>
                        <input placeholder="addresse" type="text" name="address" onChange={(e) => validateInput(e)} />
                        <div className="input-error hide">

                        </div>
                    </div>
                    <div className="form-control ">
                        <label>{t("postcode")}</label>
                        <input placeholder={t("postcode")} type="number" name="postcode" onChange={(e) => validateInput(e)} />
                        <div className="input-error hide">

                        </div>
                    </div>
                    <div className="form-control ">
                        <label>{t("note")}</label>
                        <input placeholder={t("note")} type="text" name="note" onChange={(e) => setNote(e)} />
                    </div>
                    <div className="form-control">
                        <button className="btn-blue shadow" type="submit"><FontAwesomeIcon icon={faSave}></FontAwesomeIcon> {t("next")}</button>
                    </div>
                </form>
            </div>
        }
    }

    return (
        <>
            {getInfo()}

        </>
    )
}

export default EditContactInfo
