export function formatDate(timeslot, t) {

    if (timeslot == null) {
        return null
    }
    const rawDate = new Date(timeslot.fromDate)
    const time = rawDate.toLocaleTimeString("de-DE", { hour: '2-digit', minute: '2-digit', hour12: false })

    const getDate = (someDate) => {
        const today = new Date()

        if (someDate.getDate() === today.getDate() &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear()) {
            return t("today")
        }

        else if (someDate.getDate() === today.getDate() + 1 &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear()) {
            return t("tomorrow")
        }

        else {
            return rawDate.toLocaleDateString("de-DE", { month: 'short', day: '2-digit' })
        }
    }


    return [getDate(rawDate), time]
}



export function formatDateV1(timeStamp, t) {

    if (timeStamp == null) {
        return null
    }
    const rawDate = new Date(timeStamp)
    const time = rawDate.toLocaleTimeString("de-DE", { hour: '2-digit', minute: '2-digit', hour12: false })

    const getDate = (someDate) => {
        const today = new Date()

        if (someDate.getDate() === today.getDate() &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear()) {
            return t("today")
        }

        else if (someDate.getDate() === today.getDate() + 1 &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear()) {
            return t("tomorrow")
        }

        else {
            return rawDate.toLocaleDateString("de-DE", { month: '2-digit', day: '2-digit', year: 'numeric' })

        }
    }
    return [getDate(rawDate), time]
}

export function formatDateDetailed(timeslot, t) {

    if (timeslot == null) {
        return null
    }
    const rawDate = new Date(timeslot.fromDate)
    const time = rawDate.toLocaleTimeString("de-DE", { hour: '2-digit', minute: '2-digit', hour12: false })

    const getDate = (someDate) => {
        const today = new Date()

        if (someDate.getDate() === today.getDate() &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear()) {
            return t("today")
        }

        else if (someDate.getDate() === today.getDate() + 1 &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear()) {
            return t("tomorrow")
        }

        else {
            return rawDate.toLocaleDateString("de-DE", { weekday: 'long', year: 'numeric', month: 'short', day: '2-digit' })
        }
    }
    return [getDate(rawDate), time]
}


export function compareDates(d1, d2) {
    // console.log("d1 day : "+ d1.getDate() + " d2 day:" + d2.getDate())
    // console.log("d1 month : "+ d1.getMonth() + " d2 month:" + d2.getMonth())
    // console.log("d1 year : "+ d1.getFullYear() + " d2 year:" + d2.getFullYear())
    if (d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getFullYear() === d2.getFullYear()) {
        return true
    }
    return false
}



