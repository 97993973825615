import Avatar from "../visual/Avatar"
import Title from "../visual/Title"
import { useState} from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThermometer, faPersonBooth, faUserTimes, faUserCheck, faTrashAlt, faRedo } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
const Appointmnet = ({ appointment, onAppointmentClick, apporoveAppointment, denyAppointment, sendToWatingRoom, sendToOperationRoom, statusDone, menu }) => {
    const { t } = useTranslation();
    //const date = (new Date(1616486038047)).toString("YYYY-MM-dd")
    const rawDate = new Date(appointment.timeSlots.fromDate)
    const date = rawDate.toLocaleDateString("de-DE", { year: 'numeric', month: 'short', day: '2-digit' })
    const time = rawDate.toLocaleTimeString("de-DE", { hour: '2-digit', minute: '2-digit', hour12: false })
    const [option, setOption] = useState(false)
    String.prototype.capitalize = function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    }

    const onMouseEnter = () => {
        setOption(true);
    }

    const onMouseLeave = () => {
        setOption(false);
    }

    const statusToClassName = () => {
        switch (appointment.status) {
            case 7:
                return "approve"
            case 8:
                return "deny"
            case 9:
                return "deactive"
            case 10:
                return "deactive"
        }
    }

    return (
        <div className={"appointment card-bottom-border " + statusToClassName()} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} onClick={() => onAppointmentClick(appointment)}>
            <div className="avatar-container">
                <Avatar path={appointment.contact.avatar} />
            </div>

            <div className="column">
                {
                    appointment.contact.name === null || appointment.contact.lastName === null ? <Title text={t("unknown_contact")} /> : <Title text={appointment?.contact?.name?.capitalize() + " " + appointment?.contact?.lastName?.capitalize()} />}

                <div><p className="subTitle">{appointment?.contact?.phone}</p></div>
            </div>
            <div className="date">
                <p>{date}</p>
                <p>{time}</p>
            </div>

            {option === true && menu === "approve" && <div className="appointment-overlay">
                <div className="appointment-overlay-row" onClick={(e) => apporoveAppointment(e, appointment)} ><FontAwesomeIcon title={t("approve")} icon={faUserCheck} /></div>
                <div className="appointment-overlay-row" onClick={(e) => denyAppointment(e, appointment)}><FontAwesomeIcon title={t("deny")} icon={faUserTimes} /></div>
                <div className="appointment-overlay-row" onClick={(e) => sendToWatingRoom(e, appointment)}><FontAwesomeIcon title={t("to_waitingroom")} icon={faPersonBooth} /></div>
            </div>
            }

            {
                option === true && menu === "delete" && <div className="appointment-overlay">
                    <div className="appointment-overlay-row" onClick={(e) => apporoveAppointment(e, appointment)} ><FontAwesomeIcon title={t("delete")} icon={faTrashAlt} /></div>
                    <div className="appointment-overlay-row" onClick={(e) => denyAppointment(e, appointment)}><FontAwesomeIcon title={t("undo")} icon={faRedo} /></div>
                    <div className="appointment-overlay-row"></div></div>
            }

            {
                option === true && menu === "waitingRoom" && <div className="appointment-overlay">
                    <div className="appointment-overlay-row" onClick={(e) => sendToOperationRoom(e, appointment)} ><FontAwesomeIcon title={t("to_operationroom")} icon={faThermometer} /></div>
                    <div className="appointment-overlay-row"></div></div>
            }

            {
                option === true && menu === "operationRoom" && <div className="appointment-overlay">
                    <div className="appointment-overlay-row" onClick={(e) => statusDone(e, appointment)} ><FontAwesomeIcon title={t("done")} icon={faUserCheck} /></div>
                    <div className="appointment-overlay-row"></div></div>
            }
        </div>
    )
}

export default Appointmnet
