import Contact from "./contact"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";

const Contacts = ({ onContactClick, onNewContactClick , contacts}) => {
     const { t } = useTranslation();
     return (
          <>
               <div className="appointment card-bottom-border" onClick={onNewContactClick}>
                    <div className ="avatar-container" >
                         <FontAwesomeIcon className="new-contact-icon" icon={faPlusCircle} size="3x" />
                    </div>
                    <div className="new-contact-column">
                         <p>{t("add_contact")}</p>
                    </div>
                    <div className="date">
                         <p></p>
                    </div>
               </div>

               {contacts.map((contact) => (<Contact key={contact.id} contact={contact} onContactClick={onContactClick} />))}
          </>

     )
}

export default Contacts