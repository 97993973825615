import { formatDate } from "../../utils/DateManuplation.js"
import { useTranslation } from "react-i18next";
const UserInfoUpdateConfrim = ({ appointment }) => {
    const { t } = useTranslation()
    let formatedDate = formatDate(appointment.timeSlots, t)

    return (
        <div className="userConfrimContainer">
            <div class="intro">
                <p>Liebe Patientin, Lieber Patient,</p>
                <p>Herzlichen Dank für Ihre Unterstützung! Wir würden uns freuen, Sie in unserer Praxis an folgendem Datum begrüßen zu dürfen:</p>
                <strong>{formatedDate[0]} um {formatedDate[1]}</strong>
                <p> Ihr Praxisteam.</p>
            </div>
        </div>
    )
}

export default UserInfoUpdateConfrim
