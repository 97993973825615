
import "./InfoBox.css"
const Info = ({color,text,textColor}) => {
    return (
        < div className="infoContainer" style={{background:color,color:textColor}}>
            <p>{text}</p>
        </div>
    )
}

export default Info
